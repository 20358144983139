import React from "react";
import { Container} from "@mui/material";
import { makeStyles } from '@mui/styles'
import NavMenuResponsive from './NavMenuResponsive';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Footer from './Footer/Footer'
import logo from './Images/wtw_pos_clr_cs_rgb_220126.svg'
import { useAppState } from "./Application/Appstate";
import ConfigError from "./Pages/ConfigError";

const useStyles = makeStyles({
    layout: {
        display: "flex",
        minHeight: "calc(100vh - 292px)",
        marginBottom: "55px",
        flexDirection: "column",
    }
});

export const Layout = (props) => {
    const styles = useStyles();
    const [{ appInit, configError, design }, dispatch] = useAppState();

    const theme = createTheme({
        typography: {
            fontFamily: design.font,
        },
    });
    //if (appInit == true) {
    //return <Backdrop
    //    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
    //    open={true}
    //>
    //    <CircularProgress color="inherit" />
    //</Backdrop>;
    //}

    return (
        <ThemeProvider theme={theme}>
            <div>
                <img src={logo} style={{ width: "7%", marginLeft: "auto", marginRight: "auto", display: "block", marginBottom: "20px", marginTop: "20px" }} alt="WTW Logo" />
                <Container className={styles.layout}>
                    {configError == false &&
                        <NavMenuResponsive />
                    }

                    {configError == true &&
                        <ConfigError />
                    }
                </Container>
                <Footer />
            </div>
        </ThemeProvider>
    );
};
