import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
/*import ReactDOM from 'react-dom';*/
import ReactDOM from "react-dom/client";
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import unregister from './registerServiceWorker';
import { AppStateProvider } from './components/Application/Appstate';

const baseUrl = window.location.pathname.split('/')[1];
const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <BrowserRouter basename={baseUrl}>       
        <AppStateProvider>
            <App />
        </AppStateProvider>
    </BrowserRouter>);

unregister();

