import React from "react";
import { makeStyles } from '@mui/styles'
import { Box, Grid, CircularProgress, Typography } from "@mui/material";;

const useStyles = makeStyles(
    (theme) => ({
        root: {
            marginRight: ".5rem",
        },
    }),
    { name: "Loading" }
);

const Loading = ({ label }) => {
    const styles = useStyles();
    return (
        <Box p={5}>
            <Grid container justifyContent="center" alignItems="center">
                <CircularProgress className={styles.root} />
                {label && <Typography color="primary">{label}</Typography>}
            </Grid>
        </Box>
    );
};


export default Loading;