import React from "react";
import { makeStyles } from '@mui/styles'
import { Grid, InputLabel, MenuItem, FormControl, Select, Checkbox, ListItemText, OutlinedInput } from '@mui/material';

import { useAppState } from "../../../Application/Appstate";
import { useForm, Controller } from "react-hook-form";
import { setInsuranceParametersFormData, setSkipCoinsured, setSkipInsuredPlace} from '../../../Application/Actions';
import WizardButtons from "../WizardButtons";
import SelectedInsurancePart from "./SelectedInsurancePart";




const style = makeStyles((theme) => ({
    form: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        marginTop: "25px"
    },
    select: {
        width: "80%"
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            paddingLeft: "20px"
        },
    },
};

export default function SelectInsurance({ handleBack, handleNext }) {
    const [selectedValue, setSelectedValue] = React.useState([]);
    const [{ sponsorData, insuranceParametersFormData }, dispatch] = useAppState();


    const { register, errors, handleSubmit, formState, setValue, control, reset, getValues, clearErrors } = useForm({
        mode: "onChange",
    });
    
    const { isValid } = formState;
    const classes = style();

    React.useEffect(() => {
        if (insuranceParametersFormData && insuranceParametersFormData.length > 0) {
            let result = insuranceParametersFormData.map(({ name }) => name)
            if (result) {                
                setSelectedValue(result);
            }
        }
    }, []);

    const createDateObj = () => {
        var obj = {};
        obj.insYear_Start = sponsorData.sponsorData.insYear_Start;
        obj.insYear_End = sponsorData.sponsorData.insYear_End;
        obj.insYear = sponsorData.sponsorData.insYear;
        return obj;
    }

    const GetDefaultValue = ({insuranceName}) => {
        if (insuranceParametersFormData && insuranceParametersFormData.length > 0) {
            return insuranceParametersFormData.find(o => o.name == insuranceName);
        }
    }

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedValue(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const onSubmit = data => {
        var result = [];
        var skipCoInsured = true;
        var skipInsuredPlace = true;

        selectedValue.map(function (insuranceName, i) {
            var insurance = sponsorData.insurances.find(function (ins) {
                return ins.insurance.element_CertName == insuranceName
            });

            if (insurance && insurance.insurance.coInsured == "1") {
                skipCoInsured = false;
                dispatch(setSkipCoinsured(false));
            } 

            if (insurance && insurance.insurance.fs == "1") {
                skipInsuredPlace = false;
                dispatch(setSkipInsuredPlace(false));
            } 

            if (skipCoInsured == false) {
                dispatch(setSkipCoinsured(false));
            } else {
                dispatch(setSkipCoinsured(true));
            }

            if (skipInsuredPlace == false) {
                dispatch(setSkipInsuredPlace(false));
            } else {
                dispatch(setSkipInsuredPlace(true));
            }

            var obj = {};
            obj.name = insurance.insurance.element_CertName;
            obj.id = insurance.insurance.element_Id;
            obj.params = [];
            for (var k in data) {
                const key = k.split('_');
                var param = insurance.parameters.find(function (p) {
                    return p.part_Number == key[1]
                });

                if (key[0] == insurance.insurance.element_Id) {
                    var valueItem = {};
                    valueItem.id = key[1]
                    valueItem.value = data[k];
                    valueItem.name = (param) ? param.part_Name : "";
                    obj.params.push(valueItem);
                }
            }
            result.push(obj);
        });

        dispatch(setInsuranceParametersFormData(result));
        handleNext(skipInsuredPlace,skipCoInsured);
    };
    return (
        <form onSubmit={handleSubmit(onSubmit)} className={classes.form} >
            <Grid container spacing={2}>
                <Grid item xs={4}>
                </Grid>
                <Grid item xs={4}>
                    <FormControl fullWidth>
                        <InputLabel style={{paddingLeft:"10px"}} id="selectedInsuranceId-label">Välj försäkring</InputLabel>
                        <Select
                            labelId="selectedInsuranceId-label"
                            multiple
                            value={selectedValue}
                            onChange={handleChange}
                            input={<OutlinedInput label="Tag" />}
                            renderValue={(selected) => selected.join(', ')}
                            MenuProps={MenuProps}
                        >
                            {sponsorData.insurances.map(function (item, i) {
                                return <MenuItem key={i} value={item.insurance.element_CertName}><Checkbox checked={selectedValue.indexOf(item.insurance.element_CertName) > -1} /><ListItemText primary={item.insurance.element_CertName} /></MenuItem>
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                </Grid>
                <Grid item xs={2}></Grid>
                <Grid item xs={8}>
                    {selectedValue.map(function (insuranceName, i) {
                        var insurance = sponsorData.insurances.find(function (ins) {
                            return ins.insurance.element_CertName == insuranceName
                        });
                        return <SelectedInsurancePart key={i} name={insuranceName} insuranceparams={insurance.parameters} control={control} getValues={getValues} prefix={insurance.insurance.element_Id} setValue={setValue} defaultValues={GetDefaultValue({ insuranceName })} dates={createDateObj()} formState={formState} clearErrors={clearErrors}/>;
                    })}
                </Grid>
                <Grid item xs={2}></Grid>
            </Grid>
            <WizardButtons handleBack={handleBack} isStepValid={(selectedValue.length > 0)} />
        </form>

    );
}