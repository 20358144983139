import React, { Component } from 'react';
import { makeStyles } from '@mui/styles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ArrowDownward from '@mui/icons-material/ArrowDownward';


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        margin: "15px",
    },
    table: {
        minWidth: 250,
    },
}));

function GetFileEnding(fileName) {
    return fileName.split('.').pop();
}

function GetFileNameNoExtension(fileName) {
    var parts = fileName.split('.');
    return parts[0];
}
function GetFolderName(item) {
    var folderName = `${item.fileNamePrefix}${GetFileNameNoExtension(item.fileName)}`;
    return folderName;
}

function GetFileName(item) {
    var file;
    if (item.fileNamePrefix !== null) {
        file = `${GetFolderName(item)}/${item.fileName}`;
    } else {
        file = item.fileName;
    }
    return file;
}

export default function Documents({ docs }) {
    const classes = useStyles();
    return (
        <div>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography variant="h6">Dokument</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell >Titel</TableCell>
                                <TableCell >Filtyp</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {docs && docs.map((item, index) => {
                                return (
                                    <TableRow key={index}>
                                        <TableCell align="left">
                                            <a href={process.env.PUBLIC_URL + '/Doc/' + GetFileName(item)} download target="_blank" rel="noopener noreferrer" >
                                                <ArrowDownward />{item.displayName}
                                            </a>
                                            {/*                                <Link to={'/Doc/' + item.fileName} download target="_blank">iuhref</Link>*/}
                                        </TableCell>
                                        <TableCell align="left">{GetFileEnding(item.fileName)}</TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </AccordionDetails>
            </Accordion>
            <br />
        </div>)
}