import React from "react";
import { useAppState } from "../../../Application/Appstate";
import { SummaryBox, SummaryRow } from "../SummaryComponents/Summary";


const SummaryInsured = () => {
    const [{ insuredFormData }] = useAppState();
    return (
        <SummaryBox title="Försäkringstagare">
            <SummaryRow label="Företag" value={insuredFormData.name} />
            <SummaryRow label="Org.nr" value={insuredFormData.orgNumber} />
            <SummaryRow label="Adress: " value={insuredFormData.address} />
            <SummaryRow label="Postnummer" value={insuredFormData.zipCode} />
            <SummaryRow label="Email" value={insuredFormData.email} />
            <SummaryRow label="Tel" value={insuredFormData.phone} />
            <SummaryRow label="Mobil" value={insuredFormData.mobile} />
            
            {(insuredFormData.coName && insuredFormData.useOtherAddress) && 
                <SummaryRow label="C/O" value={insuredFormData.coName} />
            }
            {(insuredFormData.coAddress && insuredFormData.useOtherAddress) &&
                <SummaryRow label="C/O Adress" value={insuredFormData.coAddress} />
            }
            {(insuredFormData.coZip && insuredFormData.useOtherAddress) &&
                <SummaryRow label="C/O Postnummer" value={insuredFormData.coZip} />
            }
            {(insuredFormData.coCity && insuredFormData.useOtherAddress) &&
                <SummaryRow label="C/O Ort" value={insuredFormData.coCity} />
            }
        </SummaryBox>
    );
};

export default SummaryInsured;
