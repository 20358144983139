import React, { useState } from "react";
import clsx from "clsx";
import { makeStyles } from '@mui/styles'
import {
    Grid,
    Container,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Link,
    Typography
} from "@mui/material";

import PhoneIcon from "@mui/icons-material/Phone";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import EmailIcon from "@mui/icons-material/Email";
import StorefrontIcon from "@mui/icons-material/Storefront";
import NoticeDialog from "../Partials/NoticeDialog";
import TermsOfUse from "../Partials/TermsOfUse";

import logo from "../Images/wtw_rev_wht_cs_rgb_220126.svg";
import { useAppState } from "../Application/Appstate";

import { Link as LinkRout } from "react-router-dom";


function GetOverViewPage(pages, categoryVal) {

    if (!pages.length) {
        return "";
    }

    var p = pages.find(({ category, parentId }) => category === categoryVal && parentId === null);
    return p;
}

const useStyles = makeStyles((theme) => ({
    root: {
        "& > a": {
            marginBottom: ".5rem",
            display: "block",
            color: "#fff",
            fontSize: 12,
            cursor: "pointer",
        },
    },
    contactFields: {
        color: "white"
    },
    secondaryLinks: {
        //"& > a": {
        //    color: theme.palette.text.hint,
        //},
    },
    dialog: {
        "& * > svg": {
            marginBottom: "-3px",
            marginRight: ".2rem",
        },
        "& > h6": {
            marginTop: "1rem",
        },
    },
    appBar: {
        background: "Grey",
        color: "#fff",
        //paddingTop: theme.spacing(3),
        //paddingBottom: theme.spacing(1),
    },
    logo: {
        maxWidth: "360px",
        height: "50px",
        verticalAlign: "middle",
        borderStyle: "none",
        marginBottom: ".5rem",
        //[theme.breakpoints.only("xs")]: {
        //    maxWidth: "340px",
        //    marginBottom: "0",
        //},
    },
    links: {
        color: "#d8d8d8",
        border: 0,
        //[theme.breakpoints.only("xs")]: {
        //    marginTop: ".5rem",
        //    paddingTop: ".5rem",
        //    borderTop: "1px solid #2a2a2a",
        //},
    },
    copyright: {
        marginTop: 15,
        paddingTop: 15,
        paddingBottom: 10,
        /* borderTop: "1px solid #2a2a2a",*/
        fontSize: 12,
    },
}));

const contactUs = {
    title: "Kontakta oss",
    content: (
        <>
            <Typography>
                <Link href="tel:+46 8 5463 5990">
                    <PhoneIcon color="primary" /> +46 8 5463 5990
                </Link>
                <br />
                <Link href="mailto:saljservice@willis.com">
                    <AlternateEmailIcon color="primary" /> saljservice@willis.com
                </Link>
            </Typography>
            <Typography variant="h6">
                <StorefrontIcon color="primary" /> Öppettider
            </Typography>
            <Typography>Vardagar 9-17</Typography>
            <Typography variant="h6">
                <EmailIcon color="primary" /> Postadress
            </Typography>
            <Typography>
                Willis Towers Watson Sweden AB
                <br /> Box 7273
                <br />
                103 89 Stockholm
            </Typography>
        </>
    ),
};


const Footer = () => {
    const styles = useStyles();
    const [open, toggleOpen] = useState(false);
    const [showCookie, setShowCookie] = useState(false);
    const [showTou, setShowTou] = useState(false);
    const [{ client, pages }, dispatch] = useAppState();

    const clickHandler = (e) => {
        e.preventDefault();
        toggleOpen(true);
    };

    return (
        <div className={styles.appBar}>
            {showCookie && <NoticeDialog onExit={() => setShowCookie(false)} />}
            {showTou && <TermsOfUse onExit={() => setShowTou(false)} />}
            <Container>
                <Grid item container direction="row">
                    <Grid item xs={12} md={6}>
                        <img src={logo} className={styles.logo} alt="Willis Towers Watson Sweden AB" />
                    </Grid>
                    <Grid container item xs={12} md={6} justifyContent="space-between" className={styles.links}>
                        <Grid item xs={6}>
                            <Typography variant="h6" className={styles.contactFields}>
                                Kontakt:
                            </Typography>
                            <Typography variant="caption" className={styles.contactFields}>
                                {client.tel} <br />
                                {client.email} <br />
                            </Typography>
                            <Typography variant="caption" className={styles.contactFields}>
                                {client.name ? "Adress:" : ""}
                            </Typography><br />

                            <Typography variant="caption" className={styles.contactFields}>
                                {client.name ? client.name + " Försäkringsservice" : ""}<br />
                                {client.name ? "Willis Towers Watson Sweden AB" : ""}<br />
                                {client.address} <br />
                                {(client.zipCode ? client.zipCode : "") + ' ' + (client.city ? client.city : "")}
                            </Typography><br />

                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="caption" className={styles.root}>
                                {GetOverViewPage(pages, 'aboutus') &&
                                    <LinkRout to={"/" + GetOverViewPage(pages, 'aboutus').name}>
                                        {GetOverViewPage(pages, 'aboutus').name}
                                    </LinkRout>
                                }
                                <Link onClick={() => setShowTou(true)}>Terms of use</Link>
                                <Link onClick={() => setShowCookie(true)}>Cookie notice</Link>
                                <Link href="https://www.wtwco.com/-/media/WTW/Notices/Code-of-Conduct-English.pdf?modified=20220818190215">
                                    Code of conduct
                                </Link>
                                <Link href="https://www.willistowerswatson.com/sv-SE/Notices/privacy-notice-crb">
                                    Privacy notice - Willis Towers Watson Sweden AB
                                    <br /> and Willis Towers Watson Oy Ab
                                </Link>
                                <Link href="https://www.wtwco.com/sv-SE/Notices/klagomal">Klagomål</Link>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={styles.copyright}>
                            Copyright © {new Date().getFullYear()} WTW. All rights reserved.
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
            <Dialog
                maxWidth="sm"
                fullWidth={true}
                open={open}
                onClose={() => toggleOpen(false)}
                aria-labelledby="dialog-title"
                aria-describedby="dialog-description"
            >
                <DialogTitle id="dialog-title" color="primary">
                    {contactUs.title}
                </DialogTitle>
                <DialogContent className={styles.dialog}>{contactUs.content}</DialogContent>
                <DialogActions>
                    <Button onClick={() => toggleOpen(false)} color="primary" autoFocus>
                        Stäng
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default Footer;
