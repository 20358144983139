
import React, { useState } from 'react';
import { makeStyles } from '@mui/styles'
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { useForm } from "react-hook-form";
import { useAppState } from "../Application/Appstate";
import ReCAPTCHA from "react-google-recaptcha";
import {validString } from "../Application/Validation";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: "15px"
    },
    paper: {
        textAlign: 'center',
        marginTop: '10px',
        padding:'10px'
    }
}));

export default function ContactUs() {
    const classes = useStyles();
    const [{ client }, dispatch] = useAppState();
    const { formState,register, handleSubmit, reset } = useForm({ shouldUseNativeValidation: true });
    const [res, setRes] = useState(null);
    const recaptchaRef = React.createRef();
    const [recap,setRecap] = useState(false);

    const onChange = async data => {
        setRecap(true);
    };

    const onSubmit = async data => {

        recaptchaRef.current.reset();
        data.clientName = client.name;
        fetch('/Contact/SendContactUs', {
            method: 'POST',
            body: JSON.stringify({ data }),
            headers: { "Content-Type": "application/json;charset=UTF-8" },
        }).then(function (response) {           
            if (response.status == 200) {
                setRes("ok");
                reset({ name: "", email: "", phone: "", org: "", message: "" })
              
                setRecap(false);
            } else {
                setRes("error");
            }
        });
    };
    return (
        <div className={classes.root}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12}>
                        <Typography variant="h3">
                            Kontakta oss
                     </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        Du kan kontakta Försäkringsservice hos WTW genom att fylla i kontaktformuläret nedan. Om du istället vill ringa eller mejla direkt till oss, hittar du våra kontaktuppgifter längst ner på sidan.
                </Grid>

                </Grid>
                <Paper elevation={3} className={classes.paper}>
                    <Grid container spacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid Grid item xs={12} sm={6}>
                            <TextField  {...register("name", { validate: (v) => validString(v) || "Ogiltig tecken" })} fullWidth label="Namn" />
                        </Grid>
                        <Grid Grid item xs={12} sm={6}>
                            <TextField {...register("email", { validate: (v) => validString(v) || "Ogiltig tecken" })} fullWidth label="Epost" />
                        </Grid>
                        <Grid Grid item xs={12} sm={6}>
                            <TextField {...register("phone", { validate: (v) => validString(v) || "Ogiltig tecken" })} fullWidth label="Telefonnummer" />
                        </Grid>
                        <Grid Grid item xs={12} sm={6}>
                            <TextField {...register("org",{ validate: (v) => validString(v) || "Ogiltig tecken" })} fullWidth label="Ditt organisationsnummer" />
                        </Grid>
                        <Grid Grid item xs={12}>
                            <TextField
                                style={{ textAlign: 'left' }}
                                {...register("message", { validate: (v) => validString(v) || "Ogiltig tecken" })}
                                label="Ange ditt ärende"                                
                                multiline
                                variant="outlined"
                                fullWidth
                                rows={3}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            </Grid>
                        <Grid item xs={4}>
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                sitekey="6LcJA3MdAAAAAFUhzXn4SAkiOS8Zo4GrO5abDOAa"
                                onChange={onChange}
                            />
                        </Grid>
                        <Grid item xs={4}>
                        </Grid>
                        <Grid Grid item xs={12}>
                            <TextField {...register("clientName")} value={client.name} hidden />
                            <Button disabled={!recap} type="submit" variant="contained">Skicka</Button>
                        </Grid>

                    </Grid>
                </Paper>
            </form>
            <br /><br />
            {(res && res == "ok") && 
                <div style={{color:"green"} }>Tack för ditt meddelande. Vi återkommer så snart som möjligt </div>
            }
            {(res && res == "error") &&
                <div style={{ color: "red"}}>Ett fel har inträffat </div>
            }
        </div >
    );
}