import React, { useState } from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    Typography,
} from "@mui/material";
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
    root: {
        marginBottom: ".8rem",
    },
    th: {
        fontWeight: "600",
    },
}));

const Paragraph = ({ children }) => {
    const styles = useStyles();
    return (
        <Typography component="p" className={styles.root}>
            {children}
        </Typography>
    );
};


const TermsOfUse = ({ onExit }) => {
    const [open, toggleOpen] = useState(true);
    const [dialog] = useState("tou");

    const contentObject = {
        tou: {
            title: "Terms of Use",
            description: () => (
                <Typography component="span" variant="body1" color="textSecondary">
                    <Paragraph>
                        The affinityplatform.com and verify-web.affinityplatform.com (the “website”) is made available by Willis
                        Towers Watson plc. and its majority-owned subsidiaries (“Willis Towers Watson”). All content, information
                        and software provided on and through the website (“content”) may be used solely under the following terms
                        and conditions (“Terms of Use”).
          </Paragraph>
                    <Paragraph>
                        YOUR USE OF THIS WEBSITE CONSTITUTES YOUR ACCEPTANCE OF THESE TERMS AND CONDITIONS. IF YOU DO NOT AGREE TO
                        THESE TERMS, YOU SHOULD IMMEDIATELY DISCONTINUE YOUR USE OF THIS WEBSITE.
          </Paragraph>
                    <ol>
                        <li>
                            <b>Website License.</b> As a user of this website, you are granted a nonexclusive, nontransferable,
              revocable, limited license to access and use the website and content in accordance with these Terms of
              Use. Willis Towers Watson may terminate this license at any time for any reason.
            </li>
                        <li>
                            <b>Limitations on Use</b>. The content on this website is for personal use only and not for commercial
              exploitation. You may use this site for your own personal use or your organization’s internal use only.
              You may not decompile, reverse-engineer, disassemble, rent, lease, loan, sell, sublicense or create
              derivative works from the website or the content. Nor may you use any network monitoring or discovery
              software to determine the site architecture, or extract information about usage or users. You may not use
              any robot, spider, or other automatic or manual device or process to monitor or copy the website or the
              content without Willis Towers Watson’s prior written permission. You may not print, download, copy,
              modify, reproduce, republish, distribute, display, or transmit for commercial, nonprofit or public
              purposes all or any portion of the website, except to the extent permitted above. You may not use or
              otherwise export or re-export the website or any portion thereof, the content or any software available on
              or through the website in violation of the export control laws and regulations of Sweden or any other
              country. Any unauthorized use of the website or the content is prohibited.
            </li>
                        <li>
                            <b>Not Professional Advice.</b> The website and the content do not constitute accounting, broking,
              consulting, investment, insurance, legal, tax or any other type of professional advice, and should be used
              only in conjunction with the services of a Willis Towers Watson consultant and any other appropriate
              professional advisors who have full knowledge of the user’s situation. The accuracy, completeness,
              adequacy or currency of the website or the content is not warranted or guaranteed. Your use of the
              content, the website or materials linked from the website is at your own risk.
            </li>
                        <li>
                            <b>Product and Service Availability.</b> Some products and services described in this website may not be
              available in all jurisdictions. The services and products described and information provided through this
              website are not directed to and are not intended for distribution to, or use by, any person or entity in
              any jurisdiction:
              <ul>
                                <li>
                                    Where such distribution or use would be contrary to law, rule or regulation (“laws”), or that would
                                    subject Willis Towers Watson to any registration requirement within such jurisdiction; or
                </li>
                                <li>Where Willis Towers Watson is not authorized to provide such information, products or services</li>
                            </ul>
              Persons who access this website are deemed to do so on their own initiative and are responsible for
              compliance with all applicable laws. Willis Towers Watson expressly prohibits the downloading or exporting
              of material from this website in violation of the laws of any applicable jurisdiction. By downloading
              material from this website, you warrant that you are doing so in full compliance with the laws of your
              resident jurisdiction.
            </li>
                        <li>
                            <b>Intellectual Property Rights.</b> All content, unless otherwise indicated, is protected by law. Except
              as expressly provided in this Terms of Use or the website, Willis Towers Watson does not grant any express
              or implied rights to you under any patent, copyright, trademark or trade secret information. Accordingly,
              any unauthorized use of the website or the content may violate copyright laws, trademark laws, patent
              laws, trade secret laws, or laws relating to privacy and publicity. By submitting content to a forum or
              any other portion of the website, you automatically grant Willis Towers Watson a royalty-free, perpetual,
              irrevocable, nonexclusive right and license to use, reproduce, modify, adopt, publish, edit (for length or
              clarity), translate, create derivative works from, distribute, redistribute, transmit, perform and display
              such content (in whole or in part) worldwide and/or to incorporate it in other works in any form, media or
              technology now known or later developed for the full term of any rights that my exist in such content.
              Willis Towers Watson reserves the right to remove any content submitted to the website at any time for any
              reason.
            </li>
                        <li>
                            <b>Errors and Corrections.</b> Willis Towers Watson does not represent or warrant that the website will be
              error-free, free of viruses or other harmful components, or that defects will be corrected. Willis Towers
              Watson does not warrant or represent that the content and any information available on or through the
              website will be correct, accurate, timely or otherwise reliable. Willis Towers Watson may make changes to
              the content or the website at any time.
            </li>
                        <li>
                            <b>Third-Party content.</b> Third-party content may appear on or be accessible from the website. Willis
              Towers Watson is not responsible for and assumes no liability for any third-party content. The website may
              provide access or links to other sites operated by third parties on the Internet. In each case, you are
              responsible for locating, reading and adhering to the terms of use of each third-party site you visit.
            </li>
                        <li>
                            <b>DISCLAIMER.</b> THE WEBSITE AND THE CONTENT IS PROVIDED ON AN "AS-IS" BASIS. WILLIS TOWERS WATSON
              EXPRESSLY DISCLAIMS ALL WARRANTIES, INCLUDING THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
              PURPOSE AND NON-INFRINGEMENT. WILLIS TOWERS WATSON DISCLAIMS ALL RESPONSIBILITY FOR ANY LOSS, INJURY,
              CLAIM, LIABILITY OR DAMAGE OF ANY KIND RESULTING FROM, ARISING OUT OF OR ANY WAY RELATED TO: (A) ANY
              ERRORS IN OR OMISSIONS FROM THIS WEBSITE AND THE CONTENT, INCLUDING BUT NOT LIMITED TO TECHNICAL
              INACCURACIES AND TYPOGRAPHICAL ERRORS; (B) ANY THIRD-PARTY WEBSITES OR CONTENT THEREIN DIRECTLY OR
              INDIRECTLY ACCESSED THROUGH LINKS IN THIS WEBSITE, INCLUDING BUT NOT LIMITED TO ANY ERRORS IN OR OMISSIONS
              THEREFROM; (C) THE UNAVAILABILITY OF THE WEBSITE OR ANY PORTION THEREOF; (D) YOUR USE OF THIS WEBSITE; OR
              (E) YOUR USE OF ANY EQUIPMENT OR SOFTWARE IN CONNECTION WITH THE WEBSITE.
            </li>
                        <li>
                            <b>LIMITATION OF LIABILITY.</b> WILLIS TOWERS WATSON SHALL NOT BE LIABLE FOR ANY LOSS, INJURY, CLAIM,
              LIABILITY OR DAMAGE OF ANY KIND RESULTING FROM YOUR USE OF THE WEBSITE OR THE CONTENT. WILLIS TOWERS
              WATSON SHALL NOT BE LIABLE FOR ANY SPECIAL, DIRECT, INDIRECT, INCIDENTAL OR CONSEQUENTIAL DAMAGES OF ANY
              KIND WHATSOEVER (INCLUDING, WITHOUT LIMITATION, ATTORNEYS' FEES) IN ANY WAY DUE TO, RESULTING FROM OR
              ARISING IN CONNECTION WITH THE USE OF OR INABILITY TO USE THE WEBSITE OR THE CONTENT. TO THE EXTENT THE
              FOREGOING LIMITATION OF LIABILITY IS PROHIBITED, WILLIS TOWERS WATSON'S SOLE OBLIGATION TO YOU FOR DAMAGES
              SHALL BE LIMITED TO SEK 10.000.
            </li>
                        <li>
                            <b>Unlawful Activity.</b> Willis Towers Watson reserves the right to investigate complaints or reported
              violations of the Terms of Use and to take any action we deem appropriate.
            </li>
                        <li>
                            <b>Remedies for Violations.</b> Willis Towers Watson reserves the right to seek all remedies available at
              law and in equity for violations of these Terms of Use, including but not limited to the right to block
              access from a particular Internet address to Willis Towers Watson’s websites and their features.
            </li>
                        <li>
                            <b>Governing Law and Jurisdiction.</b> These Terms of Use have been made in and shall be construed and
              enforced in accordance with Swedish law. Any action to enforce this agreement shall be subject to the
              exclusive jurisdiction of Swedish courts
            </li>
                        <li>
                            <b>Privacy.</b> Your use of the website is subject to Willis Towers Watson's Privacy and Cookie
              Policy, which is available on the hidden fault website.
            </li>
                        <li>
                            <b>Severability of Provisions.</b> These Terms of Use incorporate by reference any notices contained on
              the website and the Privacy Policy. These terms constitute the entire agreement between you and Willis
              Towers Watson with respect to access to, and use of, the website. If any provision of these Terms of Use
              is unlawful, void or unenforceable, or conflicts with any other provision of the Terms of Use, then the
              unlawful, void, unenforceable or conflicting provision shall be deemed severable from the remaining
              provisions and shall not affect their validity and enforceability.
            </li>
                        <li>
                            <b>Modifications to Terms of Use.</b> Willis Towers Watson reserves the right to change these Terms of Use
              at any time. Updated versions of the Terms of Use will appear on this website and are effective
              immediately. You are responsible for regularly reviewing the Terms of Use. Continued use of the website
              after any such changes constitutes your acceptance of such changes.
            </li>
                    </ol>
                </Typography>
            ),
        },
    };

    const exited = () => {
        onExit();
    };

    return (
        <Typography variant="caption">
            <Dialog
                open={open}
                onClose={() => toggleOpen(false)}
                onExited={() => exited()}
                aria-labelledby="dialog-title"
                aria-describedby="dialog-description"
                maxWidth="md"
            >
                <DialogTitle id="dialog-title" color="primary">
                    {contentObject[dialog].title}
                </DialogTitle>
                <DialogContent>{contentObject[dialog].description()}</DialogContent>
                <DialogActions>
                    <Button onClick={() => toggleOpen(false)} color="primary" autoFocus>
                        Stäng
          </Button>
                </DialogActions>
            </Dialog>
        </Typography>
    );
};

export default TermsOfUse;
