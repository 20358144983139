import React, { Component } from 'react';
import { makeStyles } from '@mui/styles'
import Grid from '@mui/material/Grid';

import Contact from '../Partials/Contact'
import TextBlock from '../Partials/TextBlock'
import Campaign from '../Partials/Campaign'

import { useAppState } from "../Application/Appstate";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        margin: "15px",
    },
    logoSize: {
        maxWidth: '100%',
        height: 'inherit'
    },
    table: {
        minWidth: 250,
    },
}));

function sortBy(arr, prop) {
    return arr.sort((a, b) => a[prop] - b[prop]);
}

function GetLogoByIndex(medias, index) {

    if (medias) {
        let filtered = medias.filter(function (el) {
            return el.logoFileName != null;
        });

        if (filtered && filtered.length > index) {
            return sortBy(filtered, 'index')[index];
        }
    }
}


function GetTextByIndex(texts, index) {
    if (texts) {
        let filtered = texts.filter(function (el) {
            return el.logoFileName != null;
        });

        if (filtered && filtered.length > index) {
            return sortBy(filtered, 'index')[index];
        }
    }
}


export default function Home({ page }) {
    const classes = useStyles();
    const [{ client }, dispatch] = useAppState();

    if (!page)
        return "";

    return (
        <div className={classes.root}>
            <Grid container spacing={2}>
                <Grid item md={12}>
                    <img className={classes.logoSize} src={process.env.PUBLIC_URL + '/Media/' + client.frontPageImage} alt="Willis Towers Watson Sweden AB" />
                </Grid>
                <Grid item md={2}></Grid>          
                <Grid item md={6}>
                    {client.campaignActive &&
                        <Campaign />
                    }
                    {page.texts && page.texts.map(function (item, index) {
                            return <Grid item><TextBlock textItem={item} /></Grid>;
                    })}
                </Grid>
                <Grid item md={2}>
                    <img className={classes.logoSize} src={process.env.PUBLIC_URL + '/Media/' + client.companyLogo} alt="Willis Towers Watson Sweden AB" />
                    {client.sponsorId &&
                        <Contact></Contact>
                    }
                </Grid>   
                <Grid item md={2}>

                </Grid>  
            </Grid>

        </div >
    );
}
