import React, { useState } from "react";
import WizardButtons from "../WizardButtons";
import { makeStyles } from '@mui/styles'
import { Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import { useAppState } from "../../../Application/Appstate";
import TextBlock from "../../TextBlock";

const style = makeStyles((theme) => ({
    form: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        marginTop: "25px"
    },
    select: {
        width: "80%"
    },
}));

export default function Intro({ handleBack, handleNext }) {
    const { register, errors, handleSubmit, formState, setValue, control, reset, clearError } = useForm({
        mode: "onChange",
    });
    const [{ client }, dispatch] = useAppState();

    const classes = style();

    const onSubmit = data => {
        handleNext();
    };

    function FormatText(header, text) {
        var item = {};
        item.header = (header)? header : "";
        item.text = (text)? text: "";
        return item;
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                </Grid>
                <Grid item xs={4}>
                    <TextBlock textItem={FormatText(client.offerIntroHeader, client.offerIntroText)} />
                </Grid>
                <Grid item xs={4}>
                </Grid>
            </Grid>
            <WizardButtons handleBack={handleBack} isStepValid={true} />
        </form>
    );
}