import React from "react";
import { useAppState } from "../../../Application/Appstate";
import { SummaryBox, SummaryRow } from "./Summary";


const SummaryInsurancePlace = ({ insurancePlace, index }) => {
    var insurancePlaceName = insurancePlace.Fs_Name ?? "";
    return (
        <SummaryBox title={"Försäkringsställe " + insurancePlaceName}>
            <SummaryRow label="Fastighetsbeteckning" value={insurancePlaceName} />
            <SummaryRow label="Adress: " value={insurancePlace.Fs_Address} />
            <SummaryRow label="Postnummer" value={insurancePlace.Fs_Zip} />
            <SummaryRow label="Postort" value={insurancePlace.Fs_Town} />
        </SummaryBox>
    );
};

export default SummaryInsurancePlace;
