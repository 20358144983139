import React from "react";
import { makeStyles } from '@mui/styles'
import { Grid, Button} from "@mui/material";
import { useAppState } from "../../Application/Appstate";

const style = makeStyles((theme) => ({
    rightWrapper: {
        justifyContent: "flex-end",
        marginTop: "1rem",
    },
    leftWrapper: {
        justifyContent: "flex-start",
        marginTop: "1rem",
    },
    button: {
        marginRight: theme.spacing(0),
    },
    root: {
        backgroundColor: "#fff",
    },
}));

const WizardButtons = ({ leftButton, handleBack, isStepValid }) => {
    const [{ activeStep }] = useAppState();
    const classes = style();

    return (
        <Grid container item>
            <Grid item container className={classes.leftWrapper} xs={6}>
                {leftButton}
            </Grid>
            <Grid item container className={classes.rightWrapper} xs={6}>
                <Button type="button" onClick={handleBack} className={classes.button}>
                    Tillbaka
          </Button>
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    type="submit"
                    disabled={!isStepValid}
                >
                    {activeStep === 5 ? "Skicka in" : "Nästa steg"}
                </Button>
            </Grid>
        </Grid>
    );
};

export default WizardButtons;
