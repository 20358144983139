export const resetOfferForms = (value) => ({
    type: "resetOfferForms",
    value: value,
});

export const setCoInsuredFormData = (value) => ({
    type: "setCoInsuredFormData",
    value: value,
});

export const setInsurancePlaceFormData = (value) => ({
    type: "setInsurancePlaceFormData",
    value: value,
});

export const setSkipInsuredPlace = (value) => ({
    type: "setSkipInsuredPlace",
    value: value,
});

export const setSkipCoinsured = (value) => ({
    type: "setSkipCoinsured",
    value: value,
});

export const setInsuranceParametersFormData = (value) => ({
    type: "setInsuranceParametersFormData",
    value: value,
});

export const setInsuredFormData = (value) => ({
    type: "setInsuredFormData",
    value: value,
});

export const setSponsorData = (value) => ({
    type: "setSponsorData",
    value: value,
});

export const setConfigError = (value) => ({
    type: "setConfigError",
    value: value,
});

export const setIsAuthenticated = (value) => ({
    type: "setIsAuthenticated",
    value: value,
});

export const setAppInit = (value) => ({
    type: "setAppInit",
    value: value,
});

export const setClient = (value) => ({
    type: "setClient",
    value: value,
});

export const setDesign = (value) => ({
    type: "setDesign",
    value: value,
});

export const setPages = (value) => ({
    type: "setPages",
    value: value,
});