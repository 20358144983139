import React from 'react';
import { makeStyles } from '@mui/styles'
import Grid from '@mui/material/Grid';


import Documents from '../Partials/Documents'
import QandA from '../Partials/QandA'
import Media from '../Partials/Media'
import TextBlock from '../Partials/TextBlock'

import { useAppState } from "../Application/Appstate";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        margin: "15px",
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    logoSize: {
        maxWidth: '100%',
        height: 'inherit'
    },
    table: {
        minWidth: 250,
    },
}));

export default function English({ page }) {
    const [, dispatch] = useAppState();

    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Grid container spacing={2}>
                {/*<Grid item xs={12} sm={12}>*/}
                {/*    <h2>{page.name}</h2>*/}
                {/*</Grid>*/}
                <Grid item md={2}>
                </Grid>
                <Grid item md={7}>
                    <table style={{ width: '100%' }}>
                        {page.texts &&
                            page.texts.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td>
                                            <TextBlock textItem={item} />
                                        </td>
                                    </tr>
                                );
                            })}
                    </table>
                </Grid>
                <Grid item md={3}>
                </Grid>
                <br />

                <Grid item md={2}>
                </Grid>
                <Grid item md={7}>
                    {page.medias &&
                        page.medias.map((item, index) => {
                            return (
                                <Media key={index} media={item} />
                            );
                        })}
                </Grid>
                <Grid item md={3}>
                </Grid>
                <br />

                <Grid item md={2}>
                </Grid>
                <Grid item md={7}>
                {(page.documents && page.documents.length > 0) &&
                   
                        <Documents docs={page.documents} />
                   
                    }
                </Grid>
                <Grid item md={3}>
                </Grid>
                <br />

                <Grid item md={2}>
                </Grid>
                <Grid item md={7}>
                {(page.qa && page.qa.length > 0) &&
                    
                        <QandA qa={page.qa} />
                   
                    }
                </Grid>
                <Grid item md={3}>
                </Grid>
                <br />
                <Grid item md={2}>
                </Grid>
            </Grid>
        </div>
    );
}
