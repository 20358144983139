const initialState = {
    activeStep: 0,
    appInit: false,
    client: {},
    design: {},
    pages: {},
    isAuthenticated: false,
    configError: false,
    sponsorData: {},
    insuredFormData: {},
    insuranceParametersFormData: {},
    coInsuredFormData: [],
    insurancePlaceFormData: [],
    skipcoInsured: false,
    skipInsuredPlace: false
};

const reducer = (state, action) => {
    switch (action.type) {
        case "resetOfferForms": {
            return {
                ...state,
                insuredFormData: {},
                insuranceParametersFormData: {},
                coInsuredFormData: [],
                insurancePlaceFormData: [],
            };
        }
        case "setCoInsuredFormData": {
            return {
                ...state,
                coInsuredFormData: action.value,
            };
        }
        case "setInsurancePlaceFormData": {
            return {
                ...state,
                insurancePlaceFormData: action.value,
            };
        }

        case "setSkipInsuredPlace": {
            return {
                ...state,
                skipInsuredPlace: action.value,
            };
        }
        case "setSkipCoinsured": {
            return {
                ...state,
                skipcoInsured: action.value,
            };
        }
        case "setInsuranceParametersFormData": {
            return {
                ...state,
                insuranceParametersFormData: action.value,
            };
        }
        case "setInsuredFormData": {
            return {
                ...state,
                insuredFormData: action.value,
            };
        }
        case "setActiveStep": {
            return {
                ...state,
                activeStep: action.payload,
            };
        }

        case "setSponsorData": {
            return {
                ...state,
                sponsorData: action.value,
            };
        }
        case "setConfigError": {
            return {
                ...state,
                configError: action.value,
            };
        }
        case "setIsAuthenticated": {
            return {
                ...state,
                isAuthenticated: action.value,
            };
        }
        case "setAppInit": {
            return {
                ...state,
                appInit: action.value,
            };
        }
        case "setClient": {
            return {
                ...state,
                client: action.value,
            };
        }
        case "setDesign": {
            return {
                ...state,
                design: action.value,
            };
        }
        case "setPages": {
            return {
                ...state,
                pages: action.value,
            };
        }
        default:
    }
    return state;
};

export { initialState, reducer };