import React, { useState } from "react";
import { makeStyles } from '@mui/styles'
import { Stepper, Step, StepLabel, Button, Typography, Box, Grid } from "@mui/material";
import {SentimentVeryDissatisfied } from "@mui/icons-material";
import StepperIcons from "./StepIcons";
import { useAppState } from "../../Application/Appstate";
import StepConnector from "./StepConnector";
import Insured from "./Steps/Insured";
import SelectInsurance from "./Steps/SelectInsurance";
import SummaryReport from "./SummaryReport";
import CoInsured from "./Steps/CoInsured";
import InsuredPlace from "./Steps/InsuredPlace";
import Intro from "./Steps/Intro";

import Loading from "./Loading";

const style = makeStyles((theme) => ({
    button: {
        marginRight: theme.spacing(1),
    },
    mainBox: {
        position: "relative",
        marginTop: "-8px",
        padding: "10px 20px",
        borderBottomRightRadius: "4px",
        borderBottomLeftRadius: "4px",
        background: theme.palette.background.default,
    },
    stepper: {
        height: "calc(10vh - 40px)",
        minHeight: "55px",
        marginBottom:"10px"
    },
    form: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
    },
    buttonWrapper: {
        justifyContent: "flex-end",
    },
}));

const StepContent = ({ step, ...rest }) => {
    switch (step) {
        case 0:
            return <Intro {...rest} />;
        case 1:
            return <Insured {...rest} />;
        case 2:
            return <SelectInsurance {...rest} />;
        case 3:
            return <CoInsured {...rest} />;
        case 4:
            return <InsuredPlace {...rest} />;
        case 5:
            return <SummaryReport {...rest} />;
        default:
            return <></>;
    }
};

const OfferStepper = () => {
    const classes = style();
    const [{ sponsorData,insurancePlaceFormData,coInsuredFormData,insuranceParametersFormData,insuredFormData,skipInsuredPlace,skipcoInsured,activeStep }, dispatch] = useAppState();
    const [busy, setBusy] = useState(false);

    const [applicationStatus, setApplicationStatus] = useState(true);
    const [applicationMessage, setApplicationMessage] = useState("");
    const [offerRef, setOfferRef] = useState("");

    const handleBack = () => {
        if (activeStep === 0) {
            dispatch({
                type: "resetOfferForms",
            });
        } else {
            var step = 1;
            if (skipcoInsured) {
                if (activeStep - step == 3) {
                    step = step + 1;
                }
            }

            if (skipInsuredPlace) {
                if (activeStep - step == 4) {
                    step = step + 1;
                }
            }
           
            dispatch({
                type: "setActiveStep",
                payload: activeStep - step,
            });
        }
    };

    const handleNext = (skipInsuredPlace, skipcoInsured) => {        

        if (activeStep === 5) {
            postApplication();
        }

        var step = 1;
        if (skipcoInsured) {
            if (activeStep + step == 3) {
                step = step + 1;
            }
        }
        
        if (skipInsuredPlace) {
            if (activeStep + step == 4) {
                step = step + 1;
            }
        }

        dispatch({
            type: "setActiveStep",
            payload: activeStep + step,
        });
    };

    const handleDone = () => {
        dispatch({
            type: "resetOfferForms",
        });
        dispatch({
            type: "setActiveStep",
            payload: 0,
        });
        //dispatch({ type: "emptyFormValue" });
        //redirect("Dolda Fel", "https://www.doldafel.se");
    };

    const postApplication = async () => {
        setBusy(true);
        setApplicationStatus(true);
        setApplicationMessage("");

        try {
            var root = {
                Insured: insuredFormData,
                InsuranceParameters: insuranceParametersFormData,
                CoInsured: coInsuredFormData,
                InsurancePlace: insurancePlaceFormData,
                Sponsor_id: sponsorData.sponsorData.sponsor_Id
            };

            const requestOptions = {
                method: 'POST',
                body: JSON.stringify({root}),
                headers: { "Content-Type": "application/json;charset=UTF-8" },
            };

            await fetch('/api/OfferApplication/', requestOptions).then(response => response.json())
                .then(data => setOfferRef(data.ref));

             

            //const data = await response.json();              

            //const result = await application.send();

            //dispatch({
            //    type: "setActiveStep",
            //    payload: 5,
            //});

            //if (!result) {
            //    setApplicationMessage("Fel vid inskickning av din anmälan");
            //}


            //if (result.reference == "-2") {
            //    setApplicationMessage("Denna anmälan verkar vara en dublett varför det inte går att komma vidare med anmälan. Kontakta gärna Säljservice för kontroll, saljservice@willis.com.");
            //    setApplicationStatus(false);
            //} else {
            //    setApplicationMessage(`Referensnummer: ${result.reference}`);
            //}


        } catch (error) {
            console.log(error);
            setApplicationMessage("Fel vid inskickning av din ansökan");
            setApplicationStatus(false);
        } finally {
            setBusy(false);
        }
    };

    const renderApplicationResult = () => {
        if (busy) return <Loading label="Sparar din ansökan" />;
        return (
            <>
                {applicationStatus ? (
                    <>                        
                        <Typography className={classes.instructions} variant="h4">
                            Tack. Din ansökan är nu inskickad. Din referens är {offerRef}
            </Typography>
                        <Typography className={classes.instructions} variant="h4">
                            {applicationMessage}
                        </Typography>
                        <Button onClick={handleDone} className={classes.button}>
                            Stäng
            </Button>
                    </>
                ) : (
                        <>
                            <SentimentVeryDissatisfied fontSize="large" color="error" />
                            <Typography className={classes.instructions} variant="h4">
                                {applicationMessage}
                            </Typography>
                            <Button onClick={handleBack} className={classes.button}>
                                Tillbaka
            </Button>
                        </>
                    )}
            </>
        );
    };

    return (
        <>
            <Stepper alternativeLabel activeStep={activeStep} connector={<StepConnector />} className={classes.stepper}>
                {/* Change the number of loops here based on StepContent */}
                {[1, 2, 3, 4, 5, 6].map((e) => (
                    <Step key={e}>
                        <StepLabel StepIconComponent={StepperIcons} />
                    </Step>
                ))}
            </Stepper>

            <Box className={classes.mainBox}>
                {activeStep === 6 ? (
                    <Grid
                        container
                        spacing={3}
                        direction="column"
                        justifyContent="space-around"
                        alignItems="center"
                        style={{ height: "400px" }}
                    >
                        {renderApplicationResult()}
                    </Grid>
                ) : (
                        <StepContent step={activeStep} handleNext={handleNext} handleBack={handleBack} />
                    )}
            </Box>
        </>
    );
};

export default OfferStepper;
