import React from "react";
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Link from "@mui/material/Link";
export default function ConfigError() {

    return (
        <Grid>
            <Grid item xs={12} style={{ textAlign: "center" }}>
                <Typography variant="h5">Välkommen till wtwgrupp.se</Typography>     
                <br/>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body2">Bakom domänen står försäkringsförmedlaren Willis Towers Watson Sweden AB (WTW). Sidan är en samlingsplats för Affinity försäkringslösningars kundwebbar.</Typography>
                <br />
                <Typography variant="body2">På respektive kundwebb samlar Försäkringsservice hos WTW all information och dokumentation som medlemmarna i respektive kundgrupp kan ha behov av. De kan bland annat läsa mer om försäkringarna samt ladda ner villkor och blanketter. Alla medlemmar kan också ringa eller mejla till Försäkringsservice för att få personlig hjälp.</Typography>
                <br />
                <Typography variant="body2">Om du vill komma i kontakt med WTW eller har frågor om sidan kan du ringa till växeln på <Link href="tel:+46 8 5463 5990">08-463 89 00</Link>, skicka e-post till  <Link href="se_info@willistowerswatson.com">                   
                </Link> se_info@willistowerswatson.com eller läsa mer på   <Link href="https://www.wtwco.com/sv-SE">
                        https://www.wtwco.com/sv-SE
                </Link></Typography>    
            </Grid>
        </Grid>

    )
}