import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    NavbarText
} from 'reactstrap';

import {
    Routes,
    Route,
    Link,
    Navigate
} from "react-router-dom";
import Home from './Pages/Home';
import Claim from './Pages/Claim';
import Insurances from './Pages/Insurances';
import ContactUs from './Pages/ContactUs';
import LoginTest from './Pages/LoginTest'
import Login from './Pages/Login'
import MyPage from './Pages/MyPage'
import English from './Pages/English'
import AboutUs from './Pages/AboutUs'
import Offer from './Pages/Offer'
import { setIsAuthenticated } from "./Application/Actions";

import './NavMenu.css'

import { useAppState } from "./Application/Appstate";

const useStyles = makeStyles((theme) => ({
    contentDiv: {
        padding: theme.spacing(2)
    },
    navItemAddon: {
        margin: '10px'
    }
}));



function GetOverViewPage(pages, categoryVal) {

    if (!pages.length) {
        return "";
    }

    var p = pages.find(({ category, parentId }) => category === categoryVal && parentId === null);
    return p;
}

function GetChildren(pages, category) {

    if (!pages.length) {
        return [];
    }
    return pages.filter(function (el) {
        return el.category === category &&
            el.parentId != null;
    });
}

function GetStartPage(pages) {

    if (!pages.length) {
        return "";
    }
    return pages.find(({ category }) => category === 'startpage');
}

const NavMenuResponsive = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);
    const classes = useStyles();

    const [{ design, pages, client, isAuthenticated }, dispatch] = useAppState();

    const logout = () => {
        dispatch(setIsAuthenticated(false));
    };

    const mobileToggle = () => {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            setIsOpen(!isOpen);
        };
    };


    return (
        <div>
            <Navbar sticky="top" style={{ backgroundColor: design.color, fontFamily: design.font }} light expand="md">
                <NavbarToggler onClick={toggle} />
                <Collapse isOpen={isOpen} navbar>
                    <Nav className="mr-auto" navbar>
                        <NavItem className={classes.navItemAddon}>
                            <Link data-toggle="collapse" onClick={() => { mobileToggle() }} className={"nav-link"} style={{ color: design.textColor }} to="/">Start</Link>
                        </NavItem>
                        <UncontrolledDropdown nav inNavbar >
                            <DropdownToggle className={classes.navItemAddon} nav caret style={{ backgroundColor: design.color, color: design.textColor }}>
                                Försäkringar
                            </DropdownToggle>
                            <DropdownMenu style={{ backgroundColor: design.color }}>
                                {GetOverViewPage(pages, 'insurance') &&
                                    <DropdownItem>
                                    <Link data-toggle="collapse" onClick={() => { mobileToggle() }} className={"nav-link"} style={{ color: design.textColor }} to={'/' + GetOverViewPage(pages, 'insurance').absolutePath}>{GetOverViewPage(pages, 'insurance').name}</Link>
                                    </DropdownItem>
                                }
                                {GetChildren(pages, 'insurance') &&
                                    GetChildren(pages, 'insurance').map((item, index) => {
                                        return (
                                            <DropdownItem key={item.name}>
                                                <Link data-toggle="collapse" onClick={() => { mobileToggle() }} className={"nav-link"} style={{ color: design.textColor }} to={"/" + item.absolutePath} >{item.name}</Link>
                                            </DropdownItem>
                                        );
                                    })}
                                {client.offerActive &&
                                    <DropdownItem>
                                    <Link data-toggle="collapse" onClick={() => { mobileToggle() }} className={"nav-link"} style={{ color: design.textColor }} to={"/Offer"}>Ansök om offert</Link>
                                    </DropdownItem>
                                }
                            </DropdownMenu>
                        </UncontrolledDropdown>
                        <UncontrolledDropdown nav inNavbar >
                            <DropdownToggle className={classes.navItemAddon} nav caret style={{ backgroundColor: design.color, color: design.textColor }}>
                                Vid skada
                            </DropdownToggle>
                            <DropdownMenu style={{ backgroundColor: design.color }}>
                                {GetOverViewPage(pages, 'claim') &&
                                    <DropdownItem>
                                    <Link data-toggle="collapse" onClick={() => { mobileToggle() }} className={"nav-link"} style={{ color: design.textColor }} to={"/" + GetOverViewPage(pages, 'claim').absolutePath}>{GetOverViewPage(pages, 'claim').name}</Link>
                                    </DropdownItem>
                                }
                                {GetChildren(pages, 'claim') &&
                                    GetChildren(pages, 'claim').map((item, index) => {
                                        return (
                                            <DropdownItem key={item.name}>
                                                <Link data-toggle="collapse" onClick={() => { mobileToggle() }} className={"nav-link"} style={{ color: design.textColor }} to={"/" + item.absolutePath} >{item.name}</Link>
                                            </DropdownItem>
                                        );
                                    })}
                            </DropdownMenu>
                        </UncontrolledDropdown>
                        {/*<NavItem>*/}
                        {/*    <Link className={"nav-link"} style={{ color: design.textColor }} to="/contact">Kontakta Oss</Link>*/}
                        {/*</NavItem>*/}
                        <NavItem className={classes.navItemAddon}>
                            <a data-toggle="collapse" onClick={() => { mobileToggle() }} className={"nav-link"} style={{ color: design.textColor }} target="_blank" rel="noopener noreferrer" href={client.companyReturnUrl}>Till {client.name}</a>
                        </NavItem>
                        {GetOverViewPage(pages, 'english') &&
                            <NavItem className={classes.navItemAddon}>
                            <Link data-toggle="collapse" onClick={() => { mobileToggle() }} className={"nav-link"} style={{ color: design.textColor }} to={"/" + GetOverViewPage(pages, 'english').absolutePath}>In English</Link>
                            </NavItem>
                        }
                    </Nav>
                    {/*   <NavbarText><Link className={"nav-link"} style={{ color: design.textColor }} to={"/logon"} onClick={() => { logout() }} >{isAuthenticated ? 'Logga ut' : 'Mina sidor'}</Link></NavbarText>*/}
                </Collapse>
            </Navbar>
            <div className={classes.contentDiv}>
                <Routes>
                    <Route exact path="/" element={<Home page={GetStartPage(pages)} />}/>

                    <Route path={"/" + GetOverViewPage(pages, 'insurance').absolutePath} element={<Insurances page={GetOverViewPage(pages, 'insurance')} />} />
                        
                    {GetChildren(pages, 'insurance') &&
                        GetChildren(pages, 'insurance').map((item, index) => {
                            return (
                                <Route key={item.name} path={"/" + item.absolutePath} element={<Insurances page={item} />} />
                            );
                        })}
                    <Route path={"/" + GetOverViewPage(pages, 'claim').absolutePath} element={<Claim page={GetOverViewPage(pages, 'claim')} />} />
                        
                    {GetChildren(pages, 'claim') &&
                        GetChildren(pages, 'claim').map((item, index) => {
                            return (
                                <Route key={item.name} path={"/" + item.absolutePath} element={<Insurances page={item}/>} />
                            );
                        })}
                    <Route path="/contact" element={<ContactUs />} />

                    {GetOverViewPage(pages, 'english') &&
                        <Route path={"/" + GetOverViewPage(pages, 'english').absolutePath} element={<English page={GetOverViewPage(pages, 'english')} /> }/>
                    }
                    {GetOverViewPage(pages, 'aboutus') &&
                        <Route path={"/" + GetOverViewPage(pages, 'aboutus').absolutePath} element={<AboutUs page={GetOverViewPage(pages, 'aboutus')} /> }/>
                    }
                    {/*<Route path="/logon">*/}
                    {/*    {isAuthenticated ? <MyPage /> : <Login />}*/}
                    {/*</Route>*/}
                    {client.offerActive &&
                        <Route path="/Offer" element={<Offer />}/>
                    }                  

                </Routes>
            </div>
        </div>
    );
}

export default NavMenuResponsive;