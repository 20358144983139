import React from "react";
import { makeStyles } from '@mui/styles'
import { TextField, Grid, Typography, Checkbox } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import WizardButtons from "../WizardButtons";
import { verifyCompanyOrgNumber, verifyPersonNumber, validateEmail, validString } from "../../../Application/Validation";
import { setInsuredFormData } from '../../../Application/Actions';
import { useAppState } from "../../../Application/Appstate";
const style = makeStyles((theme) => ({
    form: {
        display: "flex",
        flexDirection: "column",
        marginTop: "25px",
        width: "100%"        
    },
    grid: { width: "70%", marginLeft: "20%" }
}));

function stringToInt(x) {
    const parsed = parseInt(x);
    if (isNaN(parsed)) { return 0; }
    return parsed;
}

export default function Insured({ handleBack, handleNext }) {
    const { register, errors, handleSubmit, formState, setValue, control, reset, clearError } = useForm({
        mode: "onChange",
    });
    const { isValid } = formState;
    const [checked, setChecked] = React.useState(false);
    const [otherFields, setOtherFields] = React.useState([]);
    const [{ insuredFormData, sponsorData }, dispatch] = useAppState();

    const classes = style();

    const onSubmit = data => {
        dispatch(setInsuredFormData(data));
        handleNext();
    };

    React.useEffect(() => {

        var objArr = [];
        if (sponsorData) {
            if (sponsorData.sponsorData) {
                if (stringToInt(sponsorData.sponsorData.other_ShowWeb_1) > 0) {
                    objArr.push(<OtherTextField name={"other_Name_1"} required={(stringToInt(sponsorData.sponsorData.other_ShowWeb_1) == 2)} label={sponsorData.sponsorData.other_Name_1} />)
                }
                if (stringToInt(sponsorData.sponsorData.other_ShowWeb_2) > 0) {
                    objArr.push(<OtherTextField name={"other_Name_2"} required={(stringToInt(sponsorData.sponsorData.other_Name_2) == 2)} label={sponsorData.sponsorData.other_Name_2} />)
                }
                if (stringToInt(sponsorData.sponsorData.other_ShowWeb_3) > 0) {
                    objArr.push(<OtherTextField name={"other_Name_3"} required={(stringToInt(sponsorData.sponsorData.other_Name_3) == 2)} label={sponsorData.sponsorData.other_Name_3} />)
                }
                if (stringToInt(sponsorData.sponsorData.other_ShowWeb_4) > 0) {
                    objArr.push(<OtherTextField name={"other_Name_4"} required={(stringToInt(sponsorData.sponsorData.other_Name_4) == 2)} label={sponsorData.sponsorData.other_Name_4} />)
                }
                if (stringToInt(sponsorData.sponsorData.other_ShowWeb_5) > 0) {
                    objArr.push(<OtherTextField name={"other_Name_5"} required={(stringToInt(sponsorData.sponsorData.other_Name_5) == 2)} label={sponsorData.sponsorData.other_Name_5} />)
                }
            }
        }
        setOtherFields(objArr);

        for (const [key, value] of Object.entries(insuredFormData)) {
            if (key == "useOtherAddress") {
                setChecked(value);
            }
            else {
                setValue(key, value);
            }
        }

    }, []);

    const getDefaultValue = (field) => {
        if (insuredFormData) {
            const value = insuredFormData[field];

            if (value) {
                setValue(field, value, {
                    shouldDirty: true,
                    shouldValidate: true,
                });
                return value;
            } else
                return "";

        }
        return "";
    }

    const OtherTextField = ({ name, required, label }) => {
        return <Controller
            name={name}
            control={control}
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                    label={label}
                    variant="outlined"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                />
            )}
            rules={{ required: (required) ? 'Fältet är obligatoriskt' : false, validate: (v) => validString(v) || "Ogiltig tecken" }}
        />
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
            <Grid container spacing={2} className={classes.grid}>                
                {(otherFields.length > 0) &&
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant={"h5"}>Övrig information</Typography>
                        </Grid>
                        {otherFields.map((x, index) => {
                            return <Grid key={index} item xs={12} sm={6}>{x}</Grid>;
                        })
                        }
                    </Grid>
                }
                <Grid item xs={12}>
                    <br/>
                    <Typography variant={"h5"}>Om företaget</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Controller
                        name="name"
                        control={control}
                        defaultValue=""
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                                label="Företagsnamn"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                            />
                        )}
                        rules={{ required: 'Fältet är obligatoriskt', validate: (v) => validString(v) || "Ogiltig tecken" }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Controller
                        name="orgNumber"
                        defaultValue=""
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                                label="Organisationsnummer"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                            />
                        )}
                        rules={{ required: 'Orgnummer är obligatoriskt', validate: (v) => verifyCompanyOrgNumber(v) || verifyPersonNumber(v) || "Ogiltigt organisationsnummer"}}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Controller
                        name="address"
                        defaultValue=""
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                                label="Adress"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                            />
                        )}
                        rules={{ required: 'Fältet är obligatoriskt', validate: (v) => validString(v) || "Ogiltig tecken" }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Controller
                        name="zipCode"
                        defaultValue=""
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                                label="Postnummer"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                            />
                        )}
                        rules={{ required: 'Fältet är obligatoriskt', validate: (v) => validString(v) || "Ogiltig tecken" }}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Controller
                        name="city"
                        defaultValue=""
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                                label="Postort"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                            />
                        )}
                        rules={{ required: 'Fältet är obligatoriskt', validate: (v) => validString(v) || "Ogiltig tecken" }}
                    />
                </Grid>

                <Grid item xs={12}>
                    Annan fakturaadress
                    <Checkbox
                        name="useOtherAddress"
                        checked={checked}
                        onChange={() => { setChecked(!checked); setValue("useOtherAddress", !checked) }}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                </Grid>
                <Grid>
                    {checked &&
                        <Grid container direction="row"
                            spacing={1}>
                            <Grid item xs={12} sm={6}>
                                <Controller
                                    name="coName"
                                    defaultValue=""
                                    control={control}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        <TextField
                                            label="Namn fakturamottagare"
                                            variant="outlined"
                                            value={value}
                                            onChange={onChange}
                                            error={!!error}
                                            helperText={error ? error.message : null}
                                        />
                                    )}
                                    rules={{ required: 'Fältet är obligatoriskt', validate: (v) => validString(v) || "Ogiltig tecken" }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Controller
                                    name="coAdress"
                                    defaultValue=""
                                    control={control}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        <TextField
                                            label="Adress"
                                            variant="outlined"
                                            value={value}
                                            onChange={onChange}
                                            error={!!error}
                                            helperText={error ? error.message : null}
                                        />
                                    )}
                                    rules={{ required: 'Fältet är obligatoriskt', validate: (v) => validString(v) || "Ogiltig tecken" }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Controller
                                    name="coZip"
                                    defaultValue=""
                                    control={control}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        <TextField
                                            label="Postnummer"
                                            variant="outlined"
                                            value={value}
                                            onChange={onChange}
                                            error={!!error}
                                            helperText={error ? error.message : null}
                                        />
                                    )}
                                    rules={{ required: 'Fältet är obligatoriskt', validate: (v) => validString(v) || "Ogiltig tecken" }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Controller
                                    name="coCity"
                                    defaultValue=""
                                    control={control}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        <TextField
                                            label="Ort"
                                            variant="outlined"
                                            value={value}
                                            onChange={onChange}
                                            error={!!error}
                                            helperText={error ? error.message : null}
                                        />
                                    )}
                                    rules={{ required: 'Fältet är obligatoriskt', validate: (v) => validString(v) || "Ogiltig tecken" }}
                                />
                            </Grid>
                            <Grid item xs={3} md={6}>
                                <Controller
                                    name="coEmail"
                                    defaultValue=""
                                    control={control}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        <TextField
                                            label="Epost"
                                            variant="outlined"
                                            value={value}
                                            onChange={onChange}
                                            error={!!error}
                                            helperText={error ? error.message : null}
                                        />
                                    )}
                                    rules={{ required: 'Fältet är obligatoriskt', validate: (v) => validateEmail(v) || "Ogiltig email", validate: (v) => validString(v) || "Ogiltig tecken" }}
                                />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="coAtt"
                                defaultValue=""
                                control={control}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <TextField
                                        label="Kontaktperson"
                                        variant="outlined"
                                        value={value}
                                        onChange={onChange}
                                        error={!!error}
                                        helperText={error ? error.message : null}
                                    />
                                )}
                                    rules={{ required: 'Fältet är obligatoriskt', validate: (v) => validString(v) || "Ogiltig tecken" }}
                            />
                        </Grid>
                        </Grid>
                    }
                </Grid>

                <Grid item xs={12}>
                    <br />
                    <Typography variant={"h5"}>Kontaktuppgifter</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Controller
                        name="Insured_Att"
                        control={control}
                        defaultValue=""
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                                label="Kontaktperson"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                            />
                        )}
                        rules={{ required: 'Fältet är obligatoriskt', validate: (v) => validString(v) || "Ogiltig tecken" }}
                    /></Grid>
                <Grid item xs={12} sm={6}>
                    <Controller
                        name="phone"
                        defaultValue=""
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                                label="Telefonnummer"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                            />
                        )}
                        rules={{validate: (v) => validString(v) || "Ogiltig tecken" }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Controller
                        name="mobile"
                        control={control}
                        defaultValue=""
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                                label="Mobilnummer"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                            />
                        )}
                        rules={{ required: 'Fältet är obligatoriskt', validate: (v) => validString(v) || "Ogiltig tecken" }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Controller
                        name="email"
                        control={control}
                        defaultValue=""
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                                label="E-post"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                            />
                        )}
                        rules={{ required: 'Fältet är obligatoriskt', validate: (v) => validateEmail(v) || "Ogiltig email"}}
                    /></Grid>
 
            </Grid>
            <WizardButtons handleBack={handleBack} isStepValid={true} />
        </form >
    );
}