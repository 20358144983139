import React, { useState } from "react";
import { makeStyles } from '@mui/styles'
import {Grid, Typography, MenuItem, TextField, Divider } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { Controller } from "react-hook-form";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { isFuture } from "date-fns";
import FormSelect from './FormSelect';
import { validString } from "../../../Application/Validation";

const style = makeStyles((theme) => ({
    select: {
        width: "80%"
    },
    component: {
        marginBottom:"30px"
    }
}));

export default function SelectedInsurancePart({ name, insuranceparams, control, getValues, prefix, setValue, defaultValues, dates, formState, clearErrors }) {
    const classes = style();
    const [proposionalFields, setProposionalFields] = useState([]);
  
    React.useEffect(() => {
        var fields = insuranceparams.filter(function (el) {
            return el.part_Calctype == 'proportion'
        });
        var params = fields.map(function (item, index) {
            return <Grid item key={index} xs={12} sm={6}>
                <Controller
                    name={`${prefix}_${item.part_Number}`}
                    control={control}
                    defaultValue={getDefaultValueNumber(item.part_Number)}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField
                            label={item.part_CertName}
                            variant="outlined"
                            value={value}
                            onChange={onChange}
                            error={!!error}
                            helperText={error ? error.message : null}
                            type="number"
                        />
                    )}
                    rules={{ required: 'Fältet är obligatoriskt', validate: (v) => validateProporsion(v) || "Summan måste vara 100" }}
                />
                {item.part_WebDescription != "" &&
                    <Tooltip title={item.part_WebDescription}>
                        <IconButton>
                            <HelpOutlineIcon style={{ verticalAlign: "middle" }, { fontSize: "" }} />
                        </IconButton>
                    </Tooltip>
                }
            </Grid>
        })
        if (params.length > 0) {
            params.unshift(<Grid item xs={12} key={5983723}><Typography variant={"subtitle2"}>Ange hur stor andel av företagets omsättning (i procent) som utgörs av nedan verksamheter. Andelarna måste totalt summeras till 100%.</Typography></Grid>)
        }

        setProposionalFields(params)
    }, []);    

    const getDefaultValue = (field) => {
        console.log("default val");
        if (defaultValues && defaultValues.params) {
            const value = defaultValues.params.find(o => o.id == field);
            if (value) {
                setValue(`${prefix}_${field}`, value.value);
                return value.value;
            } else
                return "";
        }
        return "";
    }

    const getDefaultValueDate = (field) => {
        if (defaultValues && defaultValues.params) {
            const value = defaultValues.params.find(o => o.id == field);
            if (value) {
                return value.value;
            }
        }
        return new Date();
    }

    const getDefaultValueNumber = (field) => {
        if (defaultValues && defaultValues.params) {
            const value = defaultValues.params.find(o => o.id == field);
            if (value) {
                setValue(`${prefix}_${field}`, value.value, {
                    shouldDirty: true,
                    shouldValidate: true,
                });
                return value.value;
            } else
                return "0";
        }
        return "0";
    }
 

    const validateProporsion = ({v}) => {
        var fields = insuranceparams.filter(function (el) {
            return el.part_Calctype == 'proportion'
        });
        var total = 0;
        var i;
        fields.forEach(function myFunction(item) {
            var val = getValues(`${prefix}_${item.part_Number}`);
            if (val) {
                var intVal = parseInt(val);
                if (intVal) {
                    total = total + intVal;
                }
            }
        })
        if (total === 100) {
            clearProporsionErrors(fields);
        }
        return (total === 100);
    }

    const clearProporsionErrors = (fields) => {
        var arrKeys = [];
        fields.forEach(field => {
            var key = field.element_Id + '_' + field.part_Number;
            if (key in formState.errors) {
                arrKeys.push(key);
            }
        });
        clearErrors(arrKeys);
    }

    return (
        <Grid container spacing={2} className={classes.component} >            
            <Grid item xs={12}><Typography variant={"h6"}>{name}</Typography><Divider /></Grid>
            <Grid item xs={12}>
                <Controller
                    name={`${prefix}_startDate`}
                    control={control}
                    defaultValue={(getDefaultValueDate("startDate"))}
                    rules={{
                        validate: {
                            min: (date) => isFuture(date) || "Ange ett framtida datum"
                        }
                    }}
                    render={({ field: { ref, onBlur, name, ...field }, fieldState }) => (
                        <DatePicker
                            {...field}
                            inputRef={ref}
                            label="Date"
                            renderInput={(inputProps) => (
                                <TextField
                                    {...inputProps}
                                    onBlur={onBlur}
                                    name={name}
                                    error={!!fieldState.error}
                                    helperText={fieldState.error?.message}
                                />
                            )}
                        />
                    )}
                />    
            </Grid>
            {insuranceparams && insuranceparams.map(function (item, i) {
                if (item.part_Calctype == 'proportion')
                    return "";
                if (item.part_Control == 'dropdown') {
                    return <Grid item xs={12} sm={6} key={i}>
                        <FormSelect
                            name={`${prefix}_${item.part_Number}`}
                            className={classes.select}
                            label={item.part_CertName + ` (${item.part_EntityName})`}
                            control={control}
                            defaultValue={getDefaultValue(item.part_Number)}
                            variant="outlined"
                            margin="normal"
                        >
                            {item.dropdown ? item.dropdown.map((model, index) => {
                                // Here goes your models option
                                return <MenuItem key={index} value={model.option_ListValue}>{model.option_ListValue}</MenuItem>
                            }) : null}
                        </FormSelect>
                        {item.part_WebDescription != "" &&
                            <Tooltip title={item.part_WebDescription}>
                                <IconButton>
                                    <HelpOutlineIcon style={{ verticalAlign: "middle" }, { fontSize: "" }} />
                                </IconButton>
                            </Tooltip>
                        }
                    </Grid>
                } else {
                    return <Grid item xs={12} sm={6} key={i}>
                        <Controller
                            name={`${prefix}_${item.part_Number}`}
                            defaultValue={getDefaultValue(item.part_Number)}
                            control={control}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                    label={item.part_CertName + ` (${item.part_EntityName})`}
                                    variant="outlined"
                                    value={value}
                                    onChange={onChange}
                                    error={!!error}
                                    helperText={error ? error.message : null}
                                />
                            )}
                            rules={{ required: 'Fältet är obligatoriskt', validate: (v) => validString(v) || "Ogiltig tecken" }}
                        />
                        {item.part_WebDescription != "" &&
                            <Tooltip title={item.part_WebDescription}>
                                <IconButton>
                                    <HelpOutlineIcon style={{ verticalAlign: "middle" }, { fontSize: "" }} />
                                </IconButton>
                            </Tooltip>
                        }
                    </Grid>
                }
            })
            }
            {proposionalFields && proposionalFields.map(function (item, i) { return item; })}
        </Grid>
    );
}