import React, { Component } from 'react';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { useAppState } from "../Application/Appstate";
import TextBlock from './TextBlock';


const useStyles = makeStyles((theme) => ({
    paper: {
        //padding: theme.spacing(2),
        //textAlign: 'center',
        //marginTop: '10px',
        //marginBottom: '10px',
        //padding: '10px',
        height: '350px',
        border: '3px solid #01a0d2'
    },
    logoSize: {
        width: '100%',
        padding: "1px",
    },
}));

function FormatText(header, text) {
    var item = {};
    item.header = header;
    item.text = text;
    return item;
}

export default function Campaign() {
    const [{ client }, dispatch] = useAppState();
    const classes = useStyles();

    return (
        <Grid container spacing={2} style={{ border: '3px solid #01a0d2', padding: '20px', marginTop:'20px', marginBottom:'20px' }}>
            <Grid item xs={7}>
                <div style={{height:'100%', position:'relative'}}>
                    <TextBlock textItem={FormatText(client.campaignHeader, client.campaignText)} />
                    {/*<Link href={client.campaignLink}><Button variant="contained">Klicka här för mer info</Button></Link>*/}
                    <Button variant="contained" color='primary' href={client.campaignLink}>Klicka här för mer info</Button>
                </div>
            </Grid>
            <Grid item xs={5}>
                <img className={classes.logoSize} src={process.env.PUBLIC_URL + '/Media/' + client.campaignImageName} alt="Willis Towers Watson Sweden AB" />
            </Grid>
            {/*   <Grid item xs={12} style={{ paddingLeft: "20px", paddingBottom: "20px",textAlign:"center"}}></Grid>*/}
        </Grid>

    );
}


