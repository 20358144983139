import React from 'react';
import { makeStyles } from '@mui/styles';
import Typography from "@mui/material/Typography";
import ContactSupportIcon from '@mui/icons-material/ContactSupport';

import {
    BrowserRouter as Router,
    Link
} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        marginTop: '10px',
        padding: '10px'
    },
   
}));

export default function Contact() {

    const classes = useStyles();

    return (
        <div className={classes.paper}>
            <div >
                <Link className={"nav-linkNOT"} to="/contact">
                    <ContactSupportIcon style={{ fontSize: "90px" }} />
                </Link>
                
            </div>
            <Typography variant="body1">
                <Link className={"nav-linkNOT"} to="/contact">Kontakta oss</Link>
            </Typography>
            
        </div>
    );
}
