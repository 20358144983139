import React from "react";
import { Controller } from "react-hook-form";
import { InputLabel, FormControl, Select, FormHelperText } from '@mui/material';

const FormSelect = ({
    name,
    label,
    control,
    defaultValue,
    children,
    ...props
}) => {
    const labelId = `${name}-label`;
    const [selectedValue, setSelectedValue] = React.useState('');

      const handleChange = (event) => {
        setSelectedValue(event.target.value);      
    };

    return (
        <FormControl style={{ width: "80%" }}>
            <InputLabel id={labelId}>{label}</InputLabel>
            <Controller
                control={control}
                name={name}
                rules={{ required: 'Fältet är obligatoriskt' }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (                    
                    <>
                        <Select value={value} onChange={onChange} error={!!error}>
                            {children}
                        </Select> 
                        <FormHelperText className="str-error">
                            {error && error.message}
                        </FormHelperText>
                    </>
                )}
                defaultValue={defaultValue ?? ''}
            />
        </FormControl>
    )
};
export default FormSelect;