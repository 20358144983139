import React from "react";
import { useAppState } from "../../../Application/Appstate";
import { SummaryBox, SummaryRow } from "../SummaryComponents/Summary";


function GetInsuranceName(data, insuranceId) {
    var ins = data.insurances.find(function (ins) {
        return ins.insurance.element_Id == insuranceId
    });
    return ins?.insurance?.element_CertName;
}

const SummaryInsuranceParams = ({insuranceParams}) => {

    return (
        <SummaryBox title={insuranceParams.name}>
            {Object.keys(insuranceParams.params).map((key, index) => {
                return <SummaryRow key={index} label={insuranceParams.params[key].name} value={insuranceParams.params[key].value} />              
            })}

        </SummaryBox>
    );
};

export default SummaryInsuranceParams;
