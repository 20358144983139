import React, { Component } from 'react';
import { Layout } from './components/Layout';
import DateFnsUtils from '@date-io/date-fns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { sv } from "date-fns/locale";

import './custom.css'


export default class App extends Component {
  static displayName = App.name;

  render () {
      return (
          <LocalizationProvider locale={sv} dateAdapter={DateFnsUtils}>
              <Layout />
          </LocalizationProvider>
    );
  }
}
