import React, { Component } from 'react';
import { makeStyles } from '@mui/styles'
import Grid from '@mui/material/Grid';

import Documents from '../Partials/Documents'
import QandA from '../Partials/QandA'
import Media from '../Partials/Media'
import TextBlock from '../Partials/TextBlock'
import SideColumnImage from '../Partials/SideColumnImage'

import { useAppState } from "../Application/Appstate";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        margin: "15px",
    },
    logoSize: {
        maxWidth: '100%',
        height: 'inherit'
    },
    table: {
        minWidth: 250,
    },
}));

export default function Insurances({ page }) {
    const [, dispatch] = useAppState();

    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Grid container spacing={2}>
                <Grid item md={1}>
                </Grid>
                <Grid item md={7}>
                    <table style={{ width: '100%' }}>
                        {page.texts &&
                            page.texts.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td>
                                            <TextBlock textItem={item} />
                                        </td>
                                    </tr>
                                );
                            })}
                    </table>
                </Grid>
                <Grid item md={4}>
                    <SideColumnImage imageFile={page.rightColumnImageFileName} imageText={page.rightColumnImageText} />
                </Grid>
                <br />

                <Grid item md={1}>
                </Grid>
                <Grid item md={7}>
                    {page.medias &&
                        page.medias.map((item, index) => {
                            return (
                                <Media key={index} media={item} />
                            );
                        })}
                </Grid>
                <Grid item md={4}>
                </Grid>
                <br />

                <Grid item md={1}>
                </Grid>
                <Grid item md={7}>
                    {(page.documents && page.documents.length > 0) &&
                        <Documents docs={page.documents} />
                    }
                </Grid>
                <Grid item md={4}>
                </Grid>
                <br />

                <Grid item md={1}>
                </Grid>
                <Grid item md={7}>
                    {(page.qa && page.qa.length > 0) &&
                        <QandA qa={page.qa} />
                    }
                </Grid>
                <Grid item md={4}>
                </Grid>
            </Grid>
        </div>
    );
}
