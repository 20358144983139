import React, { Component } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextBlockString from './TextBlockString'

export default function QandA({qa}) {
    return (
        <div>
            <Typography variant="h5">
                Frågor och svar
            </Typography>
            <br/>
            {qa && qa.map((item, index) => {
                return (
                    <Accordion key={index}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <TextBlockString textItem={item.question} />
                        </AccordionSummary>
                        <AccordionDetails>
                            <TextBlockString textItem={item.answer}/>
                        </AccordionDetails>
                    </Accordion>  
                );
            })}           
        </div>
    );
}


