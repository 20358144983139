import React, { useState } from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    Typography,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from "@mui/material";
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
    root: {
        marginBottom: ".8rem",
    },
    th: {
        fontWeight: "600",
    },
}));

const Paragraph = ({ children }) => {
    const styles = useStyles();
    return (
        <Typography component="p" className={styles.root}>
            {children}
        </Typography>
    );
};

const Header = ({ children }) => {
    const styles = useStyles();
    return (
        <Typography variant="h4" className={styles.root}>
            {children}
        </Typography>
    );
};

const NoticeDialog = ({ onExit }) => {
    const styles = useStyles();
    const [open, toggleOpen] = useState(true);
    const [dialog, setDialog] = useState("cookie");

    const contentObject = {
        cookie: {
            title: "Cookie Notice",
            description: () => (
                <Typography component="span" variant="body1" color="textSecondary">
                    <Typography variant="caption" component="p">
                        Last Revised: April 2020
          </Typography>
                    <Paragraph>
                        This Cookie Notice describes the different types of cookies and similar technologies that may be applied on
                        hidden faults.
          </Paragraph>
                    <Header>1. What is a cookie?</Header>
                    <Paragraph>
                        Cookies are small text files that a website transfers to your hard drive to store and sometimes collect
                        information about your usage of websites, such as time spent on the Sites, pages visited, language
                        preferences, and other traffic data. We use the information for security purposes, to facilitate navigation,
                        to display information more effectively, to personalize your experience while using the Sites and to
                        recognize your computer in order to assist your use of the Sites. We also gather statistical information
                        about use of the Sites in order to improve their design and functionality, understand how they are used and
                        assist us with resolving questions regarding them.
          </Paragraph>
                    <Paragraph>There are different types of cookies, for example:</Paragraph>
                    <ul>
                        <li>
                            Cookies served directly by Willis Towers Watson ('first party cookies') and cookies served on our behalf,
                            for example by advertisers and data analytics companies ('third party cookies')
            </li>
                        <li>
                            Cookies which endure for different periods of time, including those that only last only as long as your
                            browser is open (referred to as 'session cookies') These are deleted automatically once you close your
                            browser. Other cookies are 'permanent cookies', meaning that they survive after your browser is closed.
                            For example, they recognize your device when you open your browser and browse the internet again.
            </li>
                    </ul>
                    <Header>2. What cookies do we use?</Header>
                    <Paragraph>
                        Below we list the different types of cookies and similar technologies that may be used on the Sites. Where
                        we use third party cookies, we provide a link to the third party's cookie policy.
          </Paragraph>
                    <Paragraph>
                        To the extent any Personal Information (which is information that identifies you as an inTypographyidual or
                        relates to an identifiable inTypographyidual) is collected through cookies, our Privacy Notice applies and
                        supplements this Cookie Notice.
          </Paragraph>
                    <Paragraph>
                        <b>Required Cookies.</b> Required cookies enable you to navigate the Sites and to use their services and
            features. Without these necessary cookies and similar technologies, the Sites will not perform as smoothly
            for you as we would like them to and we may not be able to provide the Sites or certain services or
            features.
          </Paragraph>
                    <Table className={styles.root}>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ fontWeight: "600" }}>Cookie Name</TableCell>
                                <TableCell style={{ fontWeight: "600" }}>Description of Purpose</TableCell>
                                <TableCell style={{ fontWeight: "600" }}>Persistent or session cookie</TableCell>
                                <TableCell style={{ fontWeight: "600" }}>1st party or 3rd party cookie</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>incap_ses_874_1246096</TableCell>
                                <TableCell>Ensures the security of the website by identifying user’s session on the server</TableCell>
                                <TableCell>Session</TableCell>
                                <TableCell>3rd party, Imperva. https://www.imperva.com/legal/privacy-policy/</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>visid_incap_1246096,</TableCell>
                                <TableCell>Windows application firewall cookie that ensures security of website</TableCell>
                                <TableCell>Persistent (1 year)</TableCell>
                                <TableCell>3rd party, Imperva. https://www.imperva.com/legal/privacy-policy/</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>TS011777e6, _utmvc</TableCell>
                                <TableCell>Windows application firewall cookie ensuring security of the website</TableCell>
                                <TableCell>Session</TableCell>
                                <TableCell>3rd party, Imperva https://www.imperva.com/legal/privacy-policy/</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>f5-cookie</TableCell>
                                <TableCell>
                                    Allow the load balancer to route a visitor session to stick to a certain server, for a set period of
                                    time.
                </TableCell>
                                <TableCell>Session</TableCell>
                                <TableCell>3rd party, F5 Networks, Inc, https://www.f5.com/company/policies/privacy-notice</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Cookie_Confirmation</TableCell>
                                <TableCell>Acceptance of cookie notice</TableCell>
                                <TableCell>Permanent (1 year)</TableCell>
                                <TableCell>1st party</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    <Paragraph>
                        <b>Functional Cookies.</b> Functional cookies collect information about your use of the Sites, and enable us
            to improve the way they work. For example, functional cookies show us which are the most frequently visited
            pages on the Sites, help us record any difficulties you have with the Sites, and show us whether our
            promotional campaigns are effective. This allows us to see the overall patterns of usage on the Sites. We
            use the information to analyse traffic on the Sites.
          </Paragraph>
                    <Paragraph>
                        <b>No functional cookies are being used.</b>
                    </Paragraph>
                    <Paragraph>
                        <b>Advertising Cookies.</b> Advertising cookies are set to display targeted promotions or advertisements
            based upon your interests on the Sites or to manage our advertising. These cookies collect information about
            your activities on these and other sites to provide you targeted advertising. You can find information about
            advertising cookies, including how to see what cookies have been set on your device and how to manage and
            delete them <a href="https://www.youronlinechoices.com/">here</a>.
          </Paragraph>
                    <Paragraph>
                        <b>No advertising cookies are being used.</b>
                    </Paragraph>
                    <Header>3. How do I manage cookies?</Header>
                    <Paragraph>
                        You have the right to choose whether or not to accept cookies. You can control the way in which cookies are
                        used by altering your browser settings. You may refuse to accept cookies by activating the setting on your
                        browser that allows you to reject cookies. Information about the procedure to follow in order to enable or
                        disable cookies can be found on your Internet browser provider's website via your help screen. You can
                        change your cookie settings here.
          </Paragraph>
                    <Paragraph>
                        However, if you choose not to accept cookies, this may affect the functioning of our Sites.
          </Paragraph>
                    <Paragraph>
                        If you are using a mobile device, you can limit interest-based advertising on your device by adjusting the
                        settings provided by your device manufacturer, such as "Limit Ad Tracking" for iOS or "Opt-out of
                        interest-based ads" for Android.
          </Paragraph>
                    <Paragraph>
                        To find out more about cookies, including how to see what cookies have been set and how to manage and delete
                        them, visit www.aboutcookies.org, www.allaboutcookies.org, or www.youronlinechoices.eu.
          </Paragraph>
                    <Header>4. Local Storage</Header>
                    <Paragraph>
                        Local Storage is an application programming interface that will store web data locally on the computer of
                        the of the web site user. The data will only be saved into the browser used for each specific visit to the
                        web site.
          </Paragraph>
                    <Paragraph>
                        By using Local Storage the web site provider will have the possibility to develop small applications on the
                        computer or mobile device of the web site user.
          </Paragraph>
                    <Paragraph>
                        By using the Local Storage we will give you the possibility to get acess to and navigate through our web
                        site
          </Paragraph>
                    <Paragraph>
                        If you don’t accept the storage of web data in the Local Storage or want to erase web data from the Local
                        Storage you will be able to handle this the same way in the browser settings as for Cookies.
          </Paragraph>
                    <Paragraph>
                        <b>Required use of Local Storage</b> enable you to navigate the Sites and to use their services and
            features. Without these necessary cookies and similar technologies, the Sites will not perform as smoothly
            for you as we would like them to and we may not be able to provide the Sites or certain services or
            features.
          </Paragraph>
                    <Table className={styles.root}>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ fontWeight: "600" }}>Variable Name</TableCell>
                                <TableCell style={{ fontWeight: "600" }}>Description of Purpose</TableCell>
                                <TableCell style={{ fontWeight: "600" }}>Persistent of value</TableCell>
                                <TableCell style={{ fontWeight: "600" }}>1st party or 3rd party cookie</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>wtw.se.fo.jwt</TableCell>
                                <TableCell>Store the generated token</TableCell>
                                <TableCell>Session</TableCell>
                                <TableCell>1st party</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    <Header>5. Changes to our Cookie Notice</Header>
                    <Paragraph>
                        We may change this Cookie Notice from time to time. Please take a look at the "Last Revised" legend at the
                        top of this page to see when this Cookie Notice was last revised. If we make significant changes to this
                        Cookie Notice, we will notify you of changes when you next visit any of our Sites.
          </Paragraph>
                    <Header>6. Contacts and comments</Header>
                    <Paragraph>
                        If you have any questions or comments regarding this Cookie Notice, please contact us by email at
                        privacy@willistowerswatson.com, or write to the Global Privacy Office at: (i) The Willis Building, 51 Lime
                        St, London EC3M 7DQ, United Kingdom; or (ii) 800 North Glebe Rd., Arlington, Virginia, 22203, United States.
          </Paragraph>
                </Typography>
            ),
        },
    };

    const exited = () => {
        onExit();
    };

    return (
        <Typography variant="caption">
            <Dialog
                open={open}
                onClose={() => toggleOpen(false)}
                onExited={() => exited()}
                aria-labelledby="dialog-title"
                aria-describedby="dialog-description"
                maxWidth="md"
            >
                <DialogTitle id="dialog-title" color="primary">
                    {contentObject[dialog].title}
                </DialogTitle>
                <DialogContent>{contentObject[dialog].description()}</DialogContent>
                <DialogActions>
                    <Button onClick={() => toggleOpen(false)} color="primary" autoFocus>
                        Stäng
          </Button>
                </DialogActions>
            </Dialog>
        </Typography>
    );
};

export default NoticeDialog;
