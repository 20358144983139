import React from 'react';
import Typography from "@mui/material/Typography";

export default function SideColumnImage({ imageFile, imageText }) {

    if (!imageFile)
        return null;

    return (
        <div><img style={{ paddingTop: "40px" }} src={process.env.PUBLIC_URL + '/Media/' + imageFile} alt="En illustrativ bild" width={350} height={440} /><br /><Typography style={{ paddingLeft: "10px" }} variant={"caption"}>{imageText}</Typography></div>    
    );
}