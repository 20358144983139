import React from "react";
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useAppState } from "../../Application/Appstate";
import WizardButtons from "./WizardButtons";
import SummaryInsured from "./SummaryComponents/SummaryInsured";
import SummaryInsuranceParams from "./SummaryComponents/SummaryInsuranceParams";
import SummaryConInsured from "./SummaryComponents/SummaryConInsured";
import SummaryInsurancePlace from "./SummaryComponents/SummaryInsurancePlace";
import { useForm } from "react-hook-form";

export default function SummaryReport({ handleBack, handleNext }) {
    //const [selectedValue, setSelectedValue] = React.useState(null);
    const [{ coInsuredFormData, insurancePlaceFormData, insuranceParametersFormData }, dispatch] = useAppState();
    const { handleSubmit } = useForm();

    const [approved, setApproved] = React.useState(false);

    const handleChange = (event) => {
        setApproved(event.target.checked);
    };

    const onSubmit = () => {
        handleNext();
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2} style={{ marginTop: "25px" }}>               
                <SummaryInsured />
                {insuranceParametersFormData && insuranceParametersFormData.map(function (item, i) {
                    return <SummaryInsuranceParams key={i} insuranceParams={item} />
                })}
                {coInsuredFormData && coInsuredFormData.map(function (item, i) {
                    return <SummaryConInsured key={i} conInsured={item} index={i + 1} />
                })}
                {insurancePlaceFormData && insurancePlaceFormData.map(function (item, i) {
                    return <SummaryInsurancePlace key={i} insurancePlace={item} index={i + 1} />
                })}
                <Grid item container justifyContent="flex-end" xs={12}>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox onChange={handleChange} />} label="Godkänner hantering av personuppgifter" />
                    </FormGroup>
                </Grid>
                <WizardButtons handleBack={handleBack} isStepValid={approved} />
            </Grid>
        </form>
    );
}