import React, { useState } from "react";
import { TextField, Grid, Typography, Divider } from "@mui/material";
import { Controller } from "react-hook-form";
import { validString } from "../../../Application/Validation";

export default function InsuredPlacePart({ prefix, control, name, defaultValues}) {


    return (
        <Grid container spacing={2}>
            <Grid item xs={12}><Typography variant="h5">Försäkringsställe {name+1}</Typography></Grid>
            <Grid item xs={12}><Divider /></Grid>
            <Grid item xs={12} sm={6}>
                <Controller
                    name={`${prefix}_Fs_Name`}
                    defaultValue={defaultValues?.Fs_Name}
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField
                            label={"Fastighetsbeteckning"}
                            variant="outlined"
                            value={value}
                            onChange={onChange}
                            error={!!error}
                            helperText={error ? error.message : null}
                        />
                    )}
                    rules={{validate: (v) => validString(v) || "Ogiltig tecken" }}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <Controller
                    name={`${prefix}_Fs_Address`}
                    defaultValue={defaultValues?.Fs_Address}
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField
                            label={"Adress"}
                            variant="outlined"
                            value={value}
                            onChange={onChange}
                            error={!!error}
                            helperText={error ? error.message : null}
                        />
                    )}
                    rules={{ required: 'Fältet är obligatoriskt', validate: (v) => validString(v) || "Ogiltig tecken" }}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <Controller
                    name={`${prefix}_Fs_Zip`}
                    defaultValue={defaultValues?.Fs_Zip}
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField
                            label={"Postnummer"}
                            variant="outlined"
                            value={value}
                            onChange={onChange}
                            error={!!error}
                            helperText={error ? error.message : null}
                        />
                    )}
                    rules={{ required: 'Fältet är obligatoriskt', validate: (v) => validString(v) || "Ogiltig tecken" }}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <Controller
                    name={`${prefix}_Fs_Town`}
                    defaultValue={defaultValues?.Fs_Town}
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField
                            label={"Postort"}
                            variant="outlined"
                            value={value}
                            onChange={onChange}
                            error={!!error}
                            helperText={error ? error.message : null}
                        />
                    )}
                    rules={{ required: 'Fältet är obligatoriskt', validate: (v) => validString(v) || "Ogiltig tecken" }}
                />
            </Grid>                
        </Grid>
    );
}