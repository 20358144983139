import React from "react";
import { makeStyles } from '@mui/styles'
import { Grid, Typography, TableRow, TableCell, TableBody, Table, Box } from "@mui/material";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    label: {
        fontWeight: "bold",
    },
    mainBox: {
        background: "#F6F6F6",
        width: "100%",
        marginTop: "1rem",
    },
    header: {
        marginTop: ".5rem",
        marginBottom: ".5rem",
    },
}));

export const SummaryRow = ({ label, value }) => {
    const classes = useStyles();

    if (!label || !value) return null;
    return (
        <TableRow key={label}>
            <TableCell className={classes.label} scope="row">
                {label}
            </TableCell>
            <TableCell align="right">{value}</TableCell>
        </TableRow>
    );
};

export const SummaryBox = ({ title, children }) => {
    const classes = useStyles();

    return (
        <Box className={classes.mainBox}>
            <Grid container item xs={12} justifyContent="center">
                <Typography variant="h6" className={classes.header}>
                    {title}
                </Typography>
            </Grid>
            <Table size="small" aria-label={title}>
                <TableBody>{children}</TableBody>
            </Table>
        </Box>
    );
};
