import React from 'react';

export default function Media({ media }) {
    return (
        <div>
            {(media.logoFileName) &&
                <img style={{ maxWidth: '100%' }} src={process.env.PUBLIC_URL + '/Media/' + media.logoFileName} alt="Willis Towers Watson Sweden AB" />
            }
            {(media.LogoFileName) &&
                media.VideoFileName
            }
        </div>
    );
}