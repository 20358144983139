import React, { useState } from "react";
import WizardButtons from "../WizardButtons";
import { makeStyles } from '@mui/styles'
import { Button, Grid, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { useAppState } from "../../../Application/Appstate";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import InsuredPlacePart from "./InsuredPlacePart";
import { setInsurancePlaceFormData } from "../../../Application/Actions";


const style = makeStyles((theme) => ({
    form: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        marginTop: "25px"
    },
    select: {
        width: "80%"
    },
}));

export default function InsuredPlace({ handleBack, handleNext }) {
    const [{insurancePlaceFormData}, dispatch] = useAppState();
    const { register, errors, handleSubmit, formState, setValue, control, reset, unregister } = useForm({
        mode: "onChange",
    });
    const [coFields, setCoFields] = React.useState([]);
    const [coId, setCoId] = React.useState(0);

    const classes = style();

    const onSubmit = data => {
        var dataArray = [];
        for (var i = 1; i <= coId; i++) {
            let obj = {};
            obj["Fs_Name"] = data[`${i}_Fs_Name`];
            obj["Fs_Address"] = data[`${i}_Fs_Address`];
            obj["Fs_Zip"] = data[`${i}_Fs_Zip`];
            obj["Fs_Town"] = data[`${i}_Fs_Town`];
            dataArray.push(obj);
        }
        dispatch(setInsurancePlaceFormData(dataArray));
        handleNext();
    };

    const addCo = () => {       
        setCoFields([...coFields, coId + 1]);
        setCoId(coId + 1);
    };

    const deleteCo = () => {        
        setCoFields(coFields.filter(item => item != coId));
        unregister(`${coId}_Fs_Name`);
        unregister(`${coId}_Fs_Address`);
        unregister(`${coId}_Fs_Zip`);
        unregister(`${coId}_Fs_Town`);
        setCoId(coId - 1);
    }

    React.useEffect(() => {
        let defaultcoFields = [];
        for (var i = 0; i < insurancePlaceFormData.length; i++) {
            defaultcoFields.push(i + 1);
        }
        setCoFields(defaultcoFields);
        setCoId(defaultcoFields.length);
    }, []);

    const getDefaultValues = (index) => {
        if (index > insurancePlaceFormData.length)
            return null;

        return insurancePlaceFormData[index];
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
            <Grid container spacing={2}>
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                    <Typography variant={"body2"}>Med försäkringsställe menas en adress (till exempel kontor, verkstad, butik, praktik eller lokal som används i verksamheten) som också ska omfattas av försäkringen.</Typography>
                </Grid>
                <Grid item xs={2}>
                </Grid>
                <Grid item xs={4} align="center" style={{ marginTop: "20px" }}><Button variant="contained" onClick={addCo}>Lägg till försäkringsställen<AddIcon/></Button></Grid>
                <Grid item xs={4} align="center" style={{ marginTop: "20px" }}><Button variant="contained" onClick={deleteCo}>Ta bort försäkringsställen<RemoveIcon /></Button></Grid>                
                <Grid item xs={2}>
                </Grid>
                <Grid item xs={2}>                    
                </Grid>
                <Grid item xs={8}>        
                    {coFields.map(function (item, i) {                        
                        return <InsuredPlacePart key={i} control={control} prefix={item} name={i} delCo={deleteCo} defaultValues={getDefaultValues(i)} />;
                    })}
                </Grid>    
                <Grid item xs={2}>
                </Grid>    
            </Grid>
            <WizardButtons handleBack={handleBack} isStepValid={true} />
        </form>
    );
}