const luhn = (value) => {
    let ca,
        sum = 0,
        mul = 1;
    let len = value.length;
    while (len--) {
        ca = parseInt(value.charAt(len), 10) * mul;
        sum += ca - (ca > 9) * 9;
        mul ^= 3;
    }
    return sum % 10 === 0 && sum > 0;
};

export const verifyCompanyOrgNumber = (number) => {
    if (!number) return true;
    if (!/^(\d{2})([2-9]{1})(\d{3})[\-]?(\d{4})$/.test(number)) return false;
    return luhn(`${RegExp.$1}${RegExp.$2}${RegExp.$3}${RegExp.$4}`);
};

export const verifyPersonNumber = (number) => {
    if (!number) return true;
    if (!/^(19|20)?(\d{2})(0[1-9]|10|11|12)(0[1-9]|1\d|2\d|30|31)([-\+]?)(\d{4})?$/.test(number)) return false;
    return luhn(`${RegExp.$2}${RegExp.$3}${RegExp.$4}${RegExp.$6}`);
};

export const companyTypes = {
    EstateOfDeceased: 1, // 1 – Dödsbon
    Goverment: 2, // 2 – Stat, landsting, kommuner, församlingar
    ForeignCompany: 3, // 3 – Utländska företag som bedriver näringsverksamhet eller äger fastigheter i Sverige
    LimitedCompany: 5, // 5 – Aktiebolag
    SoleTrader: 6, // 6 – Enkelt bolag
    Society: 7, // 7 – Ekonomiska föreningar, bostadsrättsföreningar
    Trusts: 8, // 8 – Ideella föreningar och stiftelser
    Partnership: 9, // 9 – Handelsbolag, kommanditbolag och enkla bolag
};

export const getCompanyOrgNumberType = (number) => {
    if (!verifyCompanyOrgNumber(number)) return null;

    for (const key in companyTypes) {
        if (companyTypes.hasOwnProperty(key) && companyTypes[key] === number[0] - "0") {
            return key;
        }
    }

    return null;
};

export const validateEmail = (v) => {
    if (!v) return true;
    return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        v
    );
};

export const validString = (v) => {
    if (!v) return true;
    return /^([0123456789 abcdefghijklmnopqrstuvwxyzåäöABCDEFGHIJKLMNOPQRSTUVWXYZÅÄÖ\&\/\-\(\)\@āáæčēëéģīïķļņšūüžõòøÆĀÁČĒËÉĢĪÏĶĻŅŠŪÜŽÒÕØ,.+'_!:]+)$/.test(
        v
    );
};

