import React, { Component } from 'react';
import Typography from "@mui/material/Typography";



function urlify(text) {
    //const urlRegex = /(https?:\/\/[^\s]+)/g;
    //return text.split(urlRegex)
    //    .map(part => {
    //        if (part.match(urlRegex)) {
    //            return <a href={part} target="_blank">{part}</a>;
    //        }
    //        return part;
    //    });
    return <div dangerouslySetInnerHTML={{ __html: text }} />
}

export default function TextBlockString({ textItem }) {
    if (!textItem)
        return "";

    return (
        <Typography variant="body1">           
                {textItem.split("\n").map(function (item, i) {
                    if (item.trim() == "") { return <br /> } else {
                        return urlify(item);
                    }
                })}
            <br />
        </Typography>
    );
}