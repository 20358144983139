import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from '@mui/styles'
import { BusinessCenter, Assignment } from "@mui/icons-material";
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import HelpIcon from '@mui/icons-material/Help';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';


const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "#ccc",
        zIndex: 1,
        color: "#fff",
        width: 50,
        height: 50,
        display: "flex",
        borderRadius: "50%",
        justifyContent: "center",
        alignItems: "center",
    },
    active: {
        background: theme.palette.primary.main,
        boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    },
    completed: {
        background: theme.palette.primary.main,
    },
}));

const StepperIcon = (props) => {
    const classes = useStyles();
    const { active, completed } = props;

    const icons = {
        1: <HelpIcon />,
        2: <BusinessCenter />,
        3: <Assignment />,
        4: <GroupAddIcon />,
        5: <LibraryAddIcon />,
        6: <AssignmentTurnedInIcon />,
    };

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
                [classes.completed]: completed,
            })}
        >
            {icons[String(props.icon)]}
        </div>
    );
};

StepperIcon.propTypes = {
    active: PropTypes.bool,
    completed: PropTypes.bool,
};

export default StepperIcon;
