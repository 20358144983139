import React from "react";
import { useAppState } from "../../../Application/Appstate";
import { SummaryBox, SummaryRow } from "./Summary";


const SummaryConInsured = ({conInsured,index}) => {
    return (
        <SummaryBox title={"Medförsäkrad " + conInsured.CoIns_Name}>
            <SummaryRow label="Namn" value={conInsured.CoIns_Name} />
            <SummaryRow label="Org.nr" value={conInsured.CoIns_OrgNbr} />
            <SummaryRow label="Adress: " value={conInsured.CoIns_Address} />
            <SummaryRow label="Postnummer" value={conInsured.CoIns_Zip} />
            <SummaryRow label="Postort" value={conInsured.CoIns_Town} />
        
        </SummaryBox>
    );
};

export default SummaryConInsured;
