import React, { createContext, useContext, useReducer, useEffect } from "react";
import { reducer, initialState } from "./Reducer";
import { setConfigError,setAppInit, setClient, setDesign, setPages} from "./Actions";

const AppStateContext = createContext();

export const useAppState = () => {
    const data = useContext(AppStateContext);
    if (!data) throw new Error(`useAppState must be used within an AppStateProvider.`);
    return data;
};

export const AppStateProvider = ({ ...props }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        const load = async () => {
            try {               
                dispatch(setConfigError(false));
                dispatch(setAppInit(true));               
                await fetchInitialData();
                dispatch(setAppInit(false));

            } catch (e) {
            } finally {
                
                dispatch(setAppInit(false));
            }
        };
        load();
    }, []);

    // basicly load all data possible
    const fetchInitialData = async () => {
        try {
            fetch(`/api/init?url=` + window.location, {             
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                },
            },
            ).then(response => {
                if (response.ok) {
                    response.json().then(json => {
                        dispatch(setClient(json.client));
                        dispatch(setDesign(json.design));
                        dispatch(setPages(json.pages));
                    });
                } else {
                    dispatch(setConfigError(true));
                }
            });
           
        } catch (error) {
            console.log(error);
        }
    };

    return <AppStateContext.Provider value={[state, dispatch]} {...props} />;
};
