import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useAppState } from "../Application/Appstate";
import OfferStepper from "../Partials/Offer/Stepper"
import CircularProgress from '@mui/material/CircularProgress';
import { setSponsorData } from "../Application/Actions";

export default function Offer() {
    const [{ client }, dispatch] = useAppState();
    const [sponsorDataLocal, setsponsorDataLocal] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const sponsorId = client.sponsorId;

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            const res = await fetch(
                `api/Sponsor?sponsorId=${sponsorId}`
            );
            const json = await res.json();
            dispatch(setSponsorData(json));
            setsponsorDataLocal(json);
            setIsLoading(false);
        };
        fetchData();
    }, [setsponsorDataLocal]);


    if (isLoading == true)
        return (<CircularProgress style={{ marginLeft: "50%"}}/>);

    return (
        <div>{ }<OfferStepper sponsorData={sponsorDataLocal} /></div>
    );
}