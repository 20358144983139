import React, { useState } from "react";
import { TextField, Grid, Typography, Divider } from "@mui/material";
import { Controller } from "react-hook-form";
import { verifyCompanyOrgNumber, verifyPersonNumber, validString } from "../../../Application/Validation";


export default function CoInsuredPart({ prefix, control, name, defaultValues}) {

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}><Typography variant="h5">Medförsäkrad {name+1}</Typography></Grid>
            <Grid item xs={12}><Divider /></Grid>
            <Grid item xs={12} sm={6}>
                <Controller
                    name={`${prefix}_CoIns_Name`}
                    defaultValue={defaultValues?.CoIns_Name}
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField
                            label={"Företagsnamn"}
                            variant="outlined"
                            value={value}
                            onChange={onChange}
                            error={!!error}
                            helperText={error ? error.message : null}
                        />
                    )}
                    rules={{ required: 'Fältet är obligatoriskt', validate: (v) => validString(v) || "Ogiltig tecken" }}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <Controller
                    name={`${prefix}_CoIns_OrgNbr`}
                    defaultValue={defaultValues?.CoIns_OrgNbr}
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField
                            label={"Organisationsnummer"}
                            variant="outlined"
                            value={value}
                            onChange={onChange}
                            error={!!error}
                            helperText={error ? error.message : null}
                        />
                    )}
                    rules={{ required: 'Orgnummer är obligatoriskt', validate: (v) => verifyCompanyOrgNumber(v) || verifyPersonNumber(v) || "Ogiltigt organisations", }}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <Controller
                    name={`${prefix}_CoIns_Address`}
                    defaultValue={defaultValues?.CoIns_Address}
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField
                            label={"Adress"}
                            variant="outlined"
                            value={value}
                            onChange={onChange}
                            error={!!error}
                            helperText={error ? error.message : null}
                        />
                    )}
                    rules={{ required: 'Fältet är obligatoriskt', validate: (v) => validString(v) || "Ogiltig tecken" }}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <Controller
                    name={`${prefix}_CoIns_Zip`}
                    defaultValue={defaultValues?.CoIns_Zip}
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField
                            label={"Postnummer"}
                            variant="outlined"
                            value={value}
                            onChange={onChange}
                            error={!!error}
                            helperText={error ? error.message : null}
                        />
                    )}
                    rules={{ required: 'Fältet är obligatoriskt', validate: (v) => validString(v) || "Ogiltig tecken" }}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <Controller
                    name={`${prefix}_CoIns_Town`}
                    defaultValue={defaultValues?.CoIns_Town}
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField
                            label={"Postort"}
                            variant="outlined"
                            value={value}
                            onChange={onChange}
                            error={!!error}
                            helperText={error ? error.message : null}
                        />
                    )}
                    rules={{ required: 'Fältet är obligatoriskt', validate: (v) => validString(v) || "Ogiltig tecken" }}
                />
            </Grid>           
        </Grid>
    );
}